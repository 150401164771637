wf-relative-measure-steps-editor {
	.modal-header {
		.modal-title {
			padding-bottom: 5px;
		}
	}

	.modal-body {
		.column-headers {
			padding: 0 15px 5px 15px;
			margin: 0 5px 10px 5px;
			overflow: visible;

			.column-header {
				span {
					margin-right: 5px;
				}
			}
		}

		.relative-measure-calculation-steps {
			.as-sortable-placeholder {
				box-shadow: 0 5px white inset, 0 -10px white inset;
				background-color: @wf_gray_lighter;
				border-bottom: 1px solid @wf_gray_lighter;
			}
		}

		.relative-measure-calculation-preview {
			display: flex;
			justify-content: center;
			padding-top: 50px;
		}

		.verification-message {
			display: flex;
			justify-content: center;
			padding-top: 40px;

			span {
				color: @wf_red;
				font-size: 14px;
			}
		}
	}

	.invalid-form {
		.relative-measure-calculation-steps {
			.invalid {
				.textbox-value {
					input {
						border-color: @wf_red;
					}
				}

				.measure {
					button {
						box-shadow: inset 0 0px 0px 1px @wf_red;
					}
				}

				.ui-select-container {
					&.ng-empty {
						.ui-select-match {
							.ui-select-toggle {
								border-color: @wf_red;
							}
						}
					}
				}
			}
		}

		.modal-body {
			.relative-measure-calculation-steps {
					.input-group {
						&.disabled {
							.ui-select-container {
								&.ng-empty {
									.ui-select-match {
										.ui-select-toggle {
											border-color: #e0e0e0;
										}
									}
								}
							}
						}
					}
			}

			.relative-measure-calculation-preview {
				.unselected-measure, .unselected-operation {
					border-bottom-color: @wf_red;
				}
			}
		}
	}
}

wf-relative-measure-calculation-preview {
	.steps-preview-wrapper {

		.operation {
			.rotate-45 { transform: rotate(-45deg); }

			.unselected-operation {
				width: 30px;
				height: 30px;
				border-bottom: 1px solid @wf_gray;
				cursor: default;

				&:before {
					margin-bottom: -4px;
				}
			}
		}

		.measure-item {
			.unselected-measure {
				width: 80px;
				height: 30px;
				border-bottom: 1px solid @wf_gray;
				cursor: default;

				&:before {
					margin-bottom: -4px;
				}
			}
		}

		.relative-measure {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			.icon {
				order: 1;
				margin-right: 10px;
				position: relative;
				i {
					color: #AFAFAF;
					text-align: center;
					margin-top: 0;
					font-size: 25px;
				}

			}

			.item-text {
				order: 2;
				font-size: 91%;
				display: flex;
				align-items: center;
			}
		}

		.calculation-steps {
			.step:first-child { .operation { display: none } }
		}

		&.default-bg-layout {
			.steps-preview-default-layout(25, 16, 250);
		}
		&.default-md-layout {
			.steps-preview-default-layout(20, 14, 200);
		}
		&.default-sm-layout {
			.steps-preview-default-layout(15, 12, 150);
		}
	}

	// ------------------------ Steps Preview Layouts ------------------------------

	.steps-preview-default-layout(@stepPadding, @fontSize, @bodyTextMaxWidth) {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;

		.operation {
			padding-left: @stepPadding * 1px;
			padding-right: @stepPadding * 1px;
			font-size: @fontSize * 1px;
		}

		.relative-measure, .step {
			// padding-top: (@stepPadding / 2) * 1px;
			// padding-bottom: (@stepPadding / 2) * 1px;
			padding-right: 0;
			padding-left: 0;
		}

		.calculation-steps {
			order: 1;

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;

			padding: 10px;
			border: 1px solid @wf_gray_lighter;
			border-radius: 3px;
		}

		.step {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;

			padding-top: 5px;
			padding-bottom: 5px;

			&.result {
				order: 2;
			}

			.value {
				&:before {
					white-space: pre;
					max-width: 300px;

					// text-cut
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				&:hover {
					color: @wf_black_darker;
				}
			}

			.item-text {
				max-height: 50px;
				overflow: hidden;
			}

			.value, .item-text, .icon {
				font-size: @fontSize * 1px !important;
				max-width: @bodyTextMaxWidth * 1px;
				text-align: center;

				i { font-size: @fontSize * 1px !important }
			}
		}
	}

	// -----------------------------------------------------------------------------
}

//Draggable item
.relative-measure-calculation-steps {

	.relative-measure-calculation-step {
		padding: 10px 15px;
		margin: 5px 0;
		border-radius: 3px;

		&:not(:last-child) { border-bottom: 1px solid @wf_gray_lighter; }

		.reorder {
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;

			&:hover {
				background-color: @wf_gray_lighter;
				border-radius: 3px;
			}

			&.as-sortable-item-handle {

			}

			.icon-wrapper {
				width: 100%;
				text-align: center;
				cursor: grab;

				i {
					cursor: grab;
				}
			}
		}

		.measure {

		}

		.constantDataSourceMetadata {
			margin-left: 5px;
			position: relative;
			top: 6px;
		}

			.input-group {
				&.disabled {
					pointer-events: none;
					opacity: 0.5;

					.ui-select-match {
						.ui-select-match-text {
							visibility: hidden;
						}
					}
				}
			}

		.policy {
			.input-group {
				.ui-select-container {
					max-width: 159px;
				}

				.ui-select-match {
					.ui-select-match-text {
						padding-right: 20px;
					}
				}

				.ui-select-choices {
					max-width: 220px;
					width: 220px;
				}
			}
		}

		.side-actions {
			.remove {
				background-color: white;
				color: @wf_red;
			}
		}
	}

	&.as-sortable-dragging {
		.relative-measure-calculation-step {
			background-color: white;
			border: 1px solid @wf_gray_lighter;

			&:hover {
				cursor: grab;
			}

			.reorder {
				background-color: @wf_gray_lighter;
				border-radius: 3px;
			}
		}
	}
}
