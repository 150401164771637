

#svgDefinitions {
	position: fixed;
	width: 500px;
	height: 500px;
	top: -1000px;
	left: -1000px;
}

.measure-chart {
	padding-top: 10px;

	.c3-chart-line circle {
		// fill: white;
		stroke-width: 2px;
		stroke: #fff;
		// stroke: #297D7D;
	}

	.c3-line {
		stroke-width: 3;
	}

	.c3-xgrid, .c3-ygrid {
		stroke: #ccc;
	}

	.c3-area {
		opacity: .09;
	}

	.c3-axis {
		.domain, .tick line {
			stroke: #ccc;
		}
	}
}

wf-chart-sunburst {
	.svgChartDirective();

	&.loading {
		.spinner(22px);
		padding-top: 100%;
	}

	.go-back {
		display: none;
		width: 41px;
		height: 41px;
		cursor: pointer;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		margin-top: -16px;
		margin-left: -21px;
		border-radius: 100%;
		color: #aaa;
		background-color: #fff;
		text-align: center;
		line-height: 42px;
		font-size: 25px;
		pointer-events: none;
		transform: rotate(45deg);
		box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.15);

		&:hover {
			background-color: #eee;
			color: #444;
		}
	}

	.svg-container {
		// margin-top: -20px;
	}

	path {
		stroke: #fff;
		stroke-width: 0.7px;
		fill-rule: evenodd;
		fill-opacity: 0.95;
		cursor: pointer;

		&:hover {
			// stroke-width: 0.1px;
			fill-opacity: 1;
		}
	}

	.dashed {
		mask: url(/#mask-stripe);
	}

	.legend {
		width: 79%;
		margin: auto;
		padding: 10px 0 15px;

		li {
			// float: left;
			padding-right: 20px;
			padding: 3px 20px 3px 0;
		}

		div {
			width: 10px;
			height: 10px;
			float: left;
			margin-right: 7px;
			position: relative;
			top: 3px;

			rect {
				// mask: url(/#mask-stripe);
			}
		}

		span {
			float: none;
			clear: none;
			overflow: hidden;
			display: block;
		}
	}
}

wf-chart-new {
	display: block;
	// padding: 20px;
	position: relative;

	> div.aggregatePeriodFrequencies {
		.chartist-series-selection {
			display: none;
		}
	}

	.top-bar {
		margin-bottom: 5px;

		> div {
			&:hover {
				//box-shadow: 0 0 3px black;
			}
		}

		.aggregate-options + .conversion-options {
			margin-left: 16px;
		}

		.conversion-options.disabled {
			cursor: not-allowed;
			opacity: 0.6;

			> * {
				pointer-events: none;
			}
		}

		.aggregate-options, .conversion-options {
			float: left;
			display: flex;


			//span {
			//	margin-left: 0;
			//	margin-right: 15px;
			//	color: @wf_gray;
			//
			//	&.selected {
			//		color: @wf_gray_dark;
			//	}
			//}

			ul.dropdown-menu {
				top: 25px;
				right: initial;
				min-width: 105px;
			}
		}

		.average-option	{
			float: left;
			margin-left: 16px;
			margin-top: 1px;
			cursor: pointer;

			background-color: transparent;
			box-shadow: none;
			padding: 0px;

			background-color: transparent !important;
			border: none !important;
			color: @wf_gray_dark;
			font-size: 10px;


			i {
				margin-left: 5px;
				font-size: 12px;
			}

			span {
				cursor: pointer;
				color: @wf_gray_dark;
				font-size: 10px;
				text-transform: uppercase;
				font-weight: 500;

				&:hover {
					color: @wf_gray_dark;
					text-decoration: none;
				}

				&.toggle-label {
					display: block;
					margin-right: 4px;
					font-weight: 400;
					font-size: 9px;
				}

				&.selected-item {
					margin-left: 5px;
					margin-right: 0;
					font-weight: 600;
				}
			}

			&:focus {
				outline: none;
			}

			&:hover {
				background-color: transparent;
				i, span.selected-item { color: darken(@wf_gray_dark, 20%); }
			}

			input {
				max-height: 0;
				max-width: 0;
				opacity: 0;
			}

			label {
				margin-bottom: 0px;
				cursor: pointer;
			}

			input + label {
				display: inline-block;
				position: absolute;
				box-shadow: inset 0 0 0px 1px rgba(255, 255, 255, 0.25);
				text-indent: -3000px;
				height: 12px;
				width: 20px;
				border-radius: 10px;
			}

			input + label:before {
				content: "";
				position: absolute;
				display: block;
				height: 12px;
				width: 12px;
				top: 0;
				left: 0;
				border-radius: 10px;
				background: rgba(19, 191, 17, 0);
				transition: .25s ease-in-out;
			}

			input + label:after {
				content: "";
				position: absolute;
				display: block;
				height: 12px;
				width: 12px;
				top: 0;
				left: 0px;
				border-radius: 10px;
				background: white;
				box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2);
				transition: .25s ease-in-out;
			}

			input:checked + label:before {
				width: 20px;
				background: @wf_green;
			}

			input:checked + label:after {
				left: 8px;
				box-shadow: inset 0 0 0 1px white, 0 2px 4px rgba(0, 0, 0, 0.2);
			}
		}

		.chart-types {
			display: flex;
			text-align: right;
			float: right;
			margin-left: 16px;

			ul.dropdown-menu {
				top: 25px;
				right: -10px;
			}
		}

		.filter-years {
			display: flex;
			text-align: right;
			float: right;
			position: relative;
			width: 50px;

			ul.dropdown-menu {
				top: 25px;
				right: 0;
				width: 67px;
				margin-right: 0;

				.year-option {
					display: flex;
					align-items: center;
					padding: 2px 5px;

					.icheckbox_blue_wf {
						width: 16.5px;
						height: 16.5px;
						background-size: 180px 18px;
						pointer-events: none;

						&.hover {
							background-position: -18px 0;
						}
						&.checked {
							background-position: -36px 0;
						}
					}


				}

				span {
					padding-left: 10px;
				}
			}
		}

		.icon-button {
			float: right;
			text-align: center;
			width: 20px;

			&.download-chart-button {
				margin-left: 15px;
			}

			i.fa-icon {
				font-size: 12px;
				font-weight: 600;
				text-decoration: none !important;
				margin-left: 0;
				// margin-top: 3px;

				//.fas class
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;

				&.save-chart-icon::before {
					content: '\f0c7';
				}

				&.download-chart-icon::before {
					content: '\f019';
				}
			}

			&.just-saved {
				i.save-chart-icon {
					color: @wf_green;
					&::before { content: '\f00c'; }
				}
			}

			&.not-saved { i.save-chart-icon { color: @wf_yellow; } }


			&[tooltip-title] {
				position: relative;
				z-index: 2;
				cursor: pointer;

				&:before,
				&:after {
					visibility: hidden;
					opacity: 0;
					pointer-events: none;
				}

				&:before {
					content: attr(tooltip-title);
					position: absolute;
					text-align: center;
					font-size: 11px;
					color: #fff;
					right: 0px;
					bottom: 150%;
					margin-bottom: -2px;
					padding: 5px 8px;
					border-radius: 2px;
					border-radius: 3px;
					background-color: rgba(24, 42, 60, 0.98);
					white-space: nowrap;
				}

				/* Triangle hack to make tooltip look like a speech bubble */
				&:after {
					position: absolute;
					bottom: 121%;
					left: 50%;
					margin-left: -5px;
					width: 0;
					border-top: 5px solid rgba(24, 42, 60, 0.98);
					border-right: 5px solid transparent;
					border-left: 5px solid transparent;
					content: " ";
					font-size: 0;
					line-height: 0;
				}

				&:hover:before,
				&:hover:after {
					visibility: visible;
					opacity: 1;
					animation: fadein .2s ease-in;
				}
			}//end of: &[data-original-title] - Tooltip
		}

		button.dropdown-toggle {
			background-color: transparent;
			box-shadow: none;
			padding: 0px;

			background-color: transparent !important;
			border: none !important;
			color: @wf_gray_dark;
			font-size: 10px;

			text-align: left;

			i {
				margin-left: 5px;
				font-size: 12px;
			}

			span {
				cursor: pointer;
				color: @wf_gray_dark;
				font-size: 10px;
				text-transform: uppercase;
				font-weight: 500;

				&:hover {
					color: @wf_gray_dark;
					text-decoration: none;
				}

				&.dropdown-label {
					margin-right: 0;
					font-weight: 400;
					font-size: 9px;
    				margin-bottom: -2px;
				}

				&.selected-item {
					margin-right: 0;
					font-weight: 600;
				}
			}

			&:focus {
				outline: none;
			}

			&:hover {
				background-color: transparent;
				i, span.selected-item { color: darken(@wf_gray_dark, 20%); }
			}
		}

		ul.dropdown-menu {
			border-radius: 3px;
			box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
			min-width: 50px;
			max-width: 200px;
			margin-right: 15px;
			margin-top: 8px;

			li {
				padding: 3px 15px 3px 30px;
				border-left: 2px solid transparent;
				line-break: auto;

				cursor: pointer;
				color: @wf_gray_dark;
				font-size: 10px;
				text-transform: uppercase;
				font-weight: 500;

				&:hover {
					background-color: fade(@wf_gray_lighter, 40%);
					cursor: pointer;
				}

				&.selected {
					padding-left: 10px;
					border-left-color: @wf_blue;
					font-weight: 500;


					&:before {
						content: '\f00c';
						font-family: FontAwesome;
						color: @wf_blue;
						margin-right: 10px;
					}
				}
			}
		}
	}
}
