@import "wf-variables.less";
@import "wf-mixins.less";

@fulfilled: @wf_green;
@unfulfilled: @wf_red;
@assessmentNeeded: @wf_yellow;
@reportingNeeded: @wf_black;
@expired: @wf_orange;
@certificateExpired: @wf_orange_dark;
@partiallyFulfilled: @wf_yellow;

@colors: @fulfilled, @partiallyFulfilled, @unfulfilled, @expired, @certificateExpired, @reportingNeeded, @assessmentNeeded;
@names: fulfilled, partiallyFulfilled, unfulfilled, expired, certificateExpired, reportingNeeded, assessmentNeeded;
@ffStates: 1, partiallyFulfilled, 0, 4, 5, 3, 2;
@icons: '\f00c', '\f056', '\f00d', '\f071', '\f071', '\f2f9', '\f252';

.for(@colorItems, @nameItems, @code) {
	& {
		.loop(@i:1) when (@i =< length(@colorItems)) {
			@value: extract(@colorItems, @i);
			@name: extract(@nameItems, @i);

			@code();

			.loop(@i + 1);
		}

		.loop();
	}
}

.forTooltip(@colorItems, @nameItems, @iconItems, @code) {
	& {
		.loop(@i:1) when (@i =< length(@colorItems)) {
			@value: extract(@colorItems, @i);
			@name: extract(@nameItems, @i);
			@icon: extract(@iconItems, @i);

			@code();

			.loop(@i + 1);
		}

		.loop();
	}
}

@keyframes pulsateOnce {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}

wf-work-item {
	.wf-work-item-wrapper {
		.for(@colors, @ffStates, {

			&.ffState-@{name} {
				// box-shadow: 0 0px 0px 20px fade(@value, 50%);
				border-radius: 2px;
				box-shadow: 0 0 0 0px fade(@value, 50%), 0 0 0 0px fade(@value, 50%);

				&.ffStateChanged {
					transition: box-shadow .5s;
					box-shadow: 0 0 0 20px fade(@value, 0%), 0 0 20px 0 fade(@value, 0%);

					wf-requirement-fulfillment {
						animation: pulsateOnce .3s;
					}
				}

				// .stateBackgroundColor {
				// 	background-color: @value;
				// }

				// .stateTextColor {
				// 	color: @value;
				// }

				// .stateBorderColor {
				// 	border-color: @value;
				// }

				// .stateBoxShadowColor {
				// 	box-shadow: inset 0 0px 0px 1px fade(@value, 50%);
				// }

				// .fulfillmentStateButton {
				// 	&:hover, &:focus, &:active {
				// 		background-color: fade(@value, 20%);
				// 	}
				// }

				// &.open {
				// 	.fulfillmentStateButton {
				// 		&, &:hover, &:focus, &:active {
				// 			background-color: fade(@value, 20%);
				// 		}
				// 	}
				// }
			}

		});
	}
}

wf-requirement-fulfillment {

	display: display-block;

	> div {
		.for(@colors, @names, {
			&.state-@{name} {
				.stateBackgroundColor {
					background-color: @value;
				}

				.stateTextColor {
					color: @value;
				}

				.stateBorderColor {
					border-color: @value;
				}

				.stateBoxShadowColor {
					box-shadow: inset 0 0px 0px 1px fade(@value, 50%);
				}

				.fulfillmentStateButton {
					&:hover, &:focus, &:active {
						background-color: fade(@value, 20%);
					}
				}

				&.open {
					.fulfillmentStateButton {
						&, &:hover, &:focus, &:active {
							background-color: fade(@value, 20%);
						}
					}
				}
			}

		});
	}

	.stateBackgroundColor {
		background-color: #aaa;
	}

	.stateTextColor {
		color: #aaa;
	}

	.stateBorderColor {
		border-color: #aaa;
	}

	.fulfillmentStateButton {
		background-color: transparent;

		.stateIcon {
			padding-right: 5px;
		}

		.dropdownIndicatorIcon {
			padding-left: 5px;
		}
	}

	.dropdown-menu {
		.popoverBoxShadow();
		padding: 20px;
		width: 250px;
		margin-top: 1px;

		.requirementText {
			font-weight: 600;
			padding-top: 7px;
			font-size: 13px;
		}

		.reportingPeriod {
			padding-top: 4px;
		}

		.statusLabel {
			color: @wf_gray;
			font-size: 11px;
			padding-top: 12px;
		}

		.stateValue {
			i {
				font-size: 18px;
				padding-right: 2px;
				padding-top: 2px;
			}

			font-size: 14px;
			text-transform: uppercase;
			font-weight: 600;
		}
	}
}

wf-fulfillment-page-bar {
	.fixed-wrapper {
		position: fixed;
		bottom: 0;
		width: 100%;
		left: 0;
		background-color: #fff;
		height: 70px;
		box-shadow: 0 0 1px 1px rgba(44, 62, 80, 0.12), 0 0 4px 3px rgba(0, 0, 0, 0.05);
		z-index: 50;

		.inner-wrapper {
			margin: auto;
			max-width: 1300px;
			height: 100%;
			justify-content: space-between;
			display: flex;

			.syncing-items-indicator {
				position: relative;
				top: -4px;
				flex-shrink: 0;
				align-self: center;

				.spinner {
					.spinner(20px);
					display: inline-block;
					width: 20px;
					height: 20px;
					position: relative;
					top: 5px;
				}

				span {
					display: inline-block;
					font-weight: 500;
					padding-left: 4px;
					padding-right: 25px;
					color: #a7a7a7;
					font-size: 14px;
				}
			}

			.bar-content {
				display: flex;
				width: 100%;

				.influence-options {
					display: flex;
					width: 100%;
					justify-content: space-between;
					flex-wrap: nowrap;
					align-items: center;

					.influence-docs-and-signing {
						display: flex;
						flex-direction: row;
						justify-content: flex-end;
						align-items: center;

						.previously-signed-documents {
							margin-right: 30px;
							button {
								.wf-work-item-secondary-button(13, 600, @wf_blue_dark, @wf_gray_hover, fade(@wf_blue_dark, 10%), 12, 16);
								padding: 4px 10px;

								&.neverSigned {
									pointer-events: none;
									cursor: default;
									background: transparent !important;

									span.subCaption {
										padding: 0;
										font-size: 14px;
									}
								}
							}
						}

						.sign-influence {
							position: relative;
							.arrow-pointer {
								visibility: hidden;
								position: absolute;
								top: -80px;
								right: 0;
								width: 100%;
								text-align: center;

								font-size: 42px;
								color: @wf_blue_dark;
							}

							&.requirements-completed {
								&.pulsateSignButton button {
									animation: pulseBoxShadowBlue 1.2s 2;
								}

								button {
									background-color: @wf_blue;
									color: white;
									&:hover {
										background-color: @wf_blue_dark;
									}

									i, span { color: white; }
								}

							}

							button {
								.wf-work-item-primary-button(@wf_gray_dark);
								font-size: 14px;
								padding: 12px 16px;
							}
						}
					}

					.influence-fulfillment {
						.loader {
							padding: 35px;
						}
					}
				}

				.profile-options {
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;

					.profile-url {
						a {
							display: flex;
							align-items: center;

							span {
								font-size: 16px;
							}

							i {
								margin-left: 10px;
							}
						}
					}

					.last-published {
						.last-published-label {
							font-size: 14px;
							color: rgba(0, 0, 0, .54);
						}

						.date {
							font-size: 14px;
							color: black;
						}
					}

					.no-address {
						i {
							margin-right: 10px;
							font-size: 16px;
							color: @wf_orange;
						}

						span {
							font-size: 16px;

							&.profile-settings-link {
								color: @wf_blue;
								cursor: pointer;

								&:hover {
									text-decoration: underline;
								}
							}
						}
					}

					button {
						margin-left: 10px;
						font-size: 14px;
						padding: 12px 16px;
						box-shadow: none;

						&.disabled {
							pointer-events: initial; //because of the tootlip
							outline: none;
						}

						&.profile-settings {
							outline: none;
							box-shadow: none;
							color: @wf_blue;
							letter-spacing: 0.02857em;
							font-size: 0.875rem;

							i { margin-right: 7px }

							&:hover {
								background-color: rgba(33, 150, 243, 0.08);
							}
						}

						&.preview {
							background-color: transparent !important;
							color: rgba(0, 0, 0, 0.26);
							text-transform: uppercase;
							border: 1px solid rgba(0, 0, 0, 0.26);
							letter-spacing: 0.02857em;
							font-size: 0.875rem;

							&.hasProfileUrl {
								color: @wf_blue;
								border: 1px solid @wf_blue;

								&:hover {
									// background-color: @wf_blue_dark;
								}
							}
						}

						&.publish {
							background-color: transparent;
							text-transform: uppercase;
							color: rgba(0, 0, 0, 0.26);
							letter-spacing: 0.02857em;
							font-size: 0.875rem;
							border: 1px solid rgba(0, 0, 0, 0.26);
							i { margin-right: 7px }

							&.hasProfileUrl {
								background-color: @wf_blue;
								border: none;
								color: white;

								&:hover {
									background-color: @wf_blue_dark;
								}

								i, span { color: white; }
							}
						}
					}
				}
			}
		}
	}

	.progress-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-wrap: nowrap;
		width: 60%;

		.progress-labels {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
			margin-bottom: 5px;

			span {
				font-size: 16px;
				font-weight: 500;
				color: @wf_black;
			}
		}

		.progress-bar-wrapper {
			display: flex;
			align-items: center;

			.progress {
				display: flex;
				width: 100%;
				// box-shadow: 0 1px 1px rgba(0,0,0,0.1) inset;
				background-color: @wf_gray_light;
				margin-bottom: 0;
				border-radius: 50px;
				height: 14px;
				position: relative;

				.progress-bar {
					position: absolute;
					left: 0;
					right: 0;

					// box-shadow: 0 1px 1px rgba(0,0,0,0.1) inset;
					border-top-right-radius: 50px;
					border-bottom-right-radius: 50px;

					&:hover {
						// box-shadow: 0 1px 1px rgba(0,0,0,0.1) inset, 0 0 0 10px rgba(0,0,0,0.05) inset;
					}
				}

				.for(@colors, @names, {
					.progress-bar.progress-bar-@{name} {
						background-color: @value;
						z-index: 60 - @i;
					}
				});
			}
		}
	}
}

.profile-settings-modal {

	.modal-header {
		.header {
			display: flex;
			align-items: center;

			i {
				font-size: 16px;
				color: @wf_black;
				margin-right: 10px;
			}
		}

		.close-button {
			position: absolute;
			right: 20px;
			top: 30px;

			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			width: 50px;
			height: 50px;

			&:hover {
				cursor: pointer;
				border-radius: 50%;
				background-color: fade(white, 5%);
			}

			i {
				color: white;
				font-size: 18px;
			}

			button {
				font-size: 28px;
			}
		}
	}

	.modal-body {
		.inline-upload-field {
			display: flex;
		}

		.profile-input-field {
			width: 100%;
			margin-left: 5px;
			label { visibility: hidden; }
		}

		.wf-base-profile-url {
			.schema-form-text {
				input { display: none; }

				&:after {
					content: 'https://profile.worldfavor.com/';
					font-size: 12px;
					display: block;
					color: @wf_black;
					margin-top: 10px;
				}
			}
		}

		.profile-cover-image {

		}
	}
}

.forTooltip(@colors, @names, @icons, {
	.progress-bar-tooltip-@{name} {
		.tooltip-arrow {
			border-top-color: @value !important;
			margin-right: 5px !important;
		}
		.tooltip-inner {
			background-color: @value !important;
			font-size: 14px !important;
			font-weight: 500 !important;

			&:before {
				content: @icon;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				margin-right: 8px;
			}
		}
	}
});

// .progress-bar-tooltip-fulfilled {
// 	.tooltip-arrow {
// 		border-top-color: @fulfilled !important;
// 		margin-right: 5px !important;
// 	}

// 	.tooltip-inner {
// 		background-color: @fulfilled !important;
// 		// color: @wf_black !important;
// 		font-size: 14px !important;
// 		font-weight: 500 !important;

// 		&:before {
// 			content: "\f00c";
// 			font-family: "Font Awesome 5 Free";
// 			font-weight: 900;
// 			margin-right: 5px;
// 		}
// 	}
// }
// .progress-bar-tooltip-unfulfilled {
// 	.tooltip-arrow {
// 		border-top-color: @unfulfilled !important;
// 		margin-right: 5px !important;
// 	}

// 	.tooltip-inner {
// 		background-color: @unfulfilled !important;
// 		// color: @wf_black !important;
// 		font-size: 14px !important;
// 		font-weight: 500 !important;
// 	}
// }
// .progress-bar-tooltip-assessmentNeeded {
// 	.tooltip-arrow {
// 		border-top-color: @assessmentNeeded !important;
// 		margin-right: 5px !important;
// 	}

// 	.tooltip-inner {
// 		background-color: @assessmentNeeded !important;
// 		// color: @wf_black !important;
// 		font-size: 14px !important;
// 		font-weight: 500 !important;
// 	}
// }
// .progress-bar-tooltip-reportingNeeded {
// 	.tooltip-arrow {
// 		border-top-color: @reportingNeeded !important;
// 		margin-right: 5px !important;
// 	}

// 	.tooltip-inner {
// 		background-color: @reportingNeeded !important;
// 		// color: @wf_black !important;
// 		font-size: 14px !important;
// 		font-weight: 500 !important;
// 	}
// }
// .progress-bar-tooltip-expired {
// 	.tooltip-arrow {
// 		border-top-color: @expired !important;
// 		margin-right: 5px !important;
// 	}

// 	.tooltip-inner {
// 		background-color: @expired !important;
// 		// color: @wf_black !important;
// 		font-size: 14px !important;
// 		font-weight: 500 !important;
// 	}
// }
// .progress-bar-tooltip-partiallyFulfilled {
// 	.tooltip-arrow {
// 		border-top-color: @partiallyFulfilled !important;
// 		margin-right: 5px !important;
// 	}

// 	.tooltip-inner {
// 		background-color: @partiallyFulfilled !important;
// 		// color: @wf_black !important;
// 		font-size: 14px !important;
// 		font-weight: 500 !important;
// 	}
// }
