@import "wf-variables.less";

//
// Maverick
//
// The Theme Layout
// --------------------------------------------------

/* Basic Layouts */
html 					{position: relative;min-height: 100px; font-size: 16px !important;}
html, body 				{min-height: 100%; height: initial !important;}

body {
	padding-top: @navbar-height;
	overflow: auto;
	overflow-y: scroll;
	@media (max-width: 520px) {
		padding-top: @navbar-height !important;
	}
	&.static-header {padding-top: 0!important;}
}

#wrapper 				{display:table;width: 100%; table-layout: fixed; overflow: auto; height: 100%; position: relative;}
#layout-static 			{display: table-row; height: 100%;}
.static-sidebar-wrapper {display: table-cell; overflow: hidden;}
.static-sidebar-wrapper, .static-sidebar {width: 240px;}
.static-content-wrapper {display: table-cell; position: relative; background: @wf_gray_light; padding-bottom: 130px;}
.static-sidebar-wrapper, .static-content-wrapper {vertical-align: top;}
footer					{background: @wf_gray_lighter;}


/* Fix the Grid */
#wrap > .container-fluid,
#wrap > .container {
	padding: 20px;
}

.container-fluid,
.container {
	padding-left: 0;
	padding-right: 0;
}

/* Logo */
@navbar-brand-width: 40px;
.navbar-brand {.text-hide() !important; background: url("../img/logos/wf_logo_white_filled.svg") no-repeat center; background-size: contain; border-radius: 2px; height: 36px; width: 36px; margin-right: 32px !important; margin: 7px 0; }
.navbar-default .navbar-brand {.text-hide() !important; background: url("../img/logos/wf_logo_white_filled.svg") no-repeat left bottom; height: @navbar-height;width: @navbar-brand-width;}
.logo-pride { background-image: url("../img/logos/wf_logo_pride.png")}

// In small screens header becomes two lines
@media (max-width: 520px) {
	ul.toolbar {display: block; float: none !important; margin: 0;}
	//.navbar-header {margin: 0 auto;float: none !important; width: @navbar-brand-width;}
	// .navbar-header {display: none;}

	body {padding-top: 80px;}
	.infobar, #sidebar {top: 50px;}
}

/* Headerbar */
#headerbar {
	&:extend(.clearfix all);
	.transition(~"top .25s ease");
	top: -1000px; left: 0;
	position: fixed;
	padding:20px 20px 0;
	z-index: 9999;
	width: 100%;
	background-color: #fff;
	background-color: rgba(255,255,255,0.86);

	&.headerbarHide {top: -1000px !important;}
	&.headerbarShow {top: 0 !important;}
}

/* Footer */

footer {
	background: @wf_gray_lighter;
	color: @gray;

	padding: 5px 0 20px;
	//position: absolute;
	//left: 0;
	//right: 0;
	//bottom: 0;


	ul {margin: 0;}


	>div.clearfix {
		/*font-size: @font-size-min;*/
		color: @gray;
		font-size: 13px;

		a {
			color: @gray-darker;
			&:hover {
				color: lighten(@gray-darker, 20%);
				text-decoration: none !important;
			}
		}
		ul {
			margin-bottom: -2px;
			li:last-child {padding-right: 0;}

			.company-name {
				float: left;
				padding-left: 0px;
			}

			.company-address {
				float: left;
			}

			.company-rights {
				float: left;
			}

			.company-terms {
				float: right;
			}
		}
	}

	#back-to-top {
		position: absolute;
		right: 0;
		top: 5px;
	}
}

/* Page heading */

#page-heading {
	background: @page-title-color;
	padding: 8px 0 32px;
	&:extend(.clearfix all);


	.breadcrumb {
		> li {
			font-size: 11px !important;
			line-height: 10px;
		}
		line-height: 10px;
		padding: 10px 0;
		font-size: 11px;
		border-radius: 0;
		background: @breadcrumb-bg;
	}

	h1 {margin: 0;padding: 20px 0; float: left; font-weight: 300; line-height: 34px; font-weight: 300; font-size: 20px;}
	.options {display: inline; float: right; position: relative; top: 20px; right: 0}

	p {
		clear: both;
		max-width: 600px;
	}
}

// Don't show sidebar or header when printing

@media print {
	.static-sidebar-wrapper, .infobar-wrapper, #topnav, #headernav {display: none !important;}

	.btn
	, .follow-up-information
	, .ui-mode-switcher
	, .backButton
	, .jss7
	, .modal.guidance.wf-modal.fade-in-and-scale-up.fade.in {
		display: none !important;
	}
}



//Top Navigation Changes
@media (max-width: @screen-xs-max) {
	header .navbar-collapse.in {overflow-y: visible}
	#sidebar {
		position: fixed;
		width: 0;
		top: @navbar-height; bottom: 0px;
		overflow-x: hidden !important;
	}
	body.show-sidebar #sidebar {
		width: 240px;
	}
}

//Login page
.focusedform {
	height: 100%;
	background: @sidebar-default-background;
	padding: 0;

	.static-sidebar-wrapper {width: 0;}


	.infobar, header {display: none;}

	.panel {width: 400px;}
	.brand {display: block; padding-bottom: 40px; margin: 0 auto; width: 224px;}

	.verticalcenter {
		width: 400px;
		margin: 5% auto 0;
	}

	.infobar-wrapper{
  		display: none;
	}
}
@media (max-width: @screen-xs-max) {
	.focusedform {
		.verticalcenter {width: 380px;}
	}
}




/*Horizontal Navigation*/

.navbar {box-shadow: none; }

.layout-horizontal {
	.nav-separator {display: none;}

	#sidebar, #leftmenu-trigger {display: none;}

	nav.navbar {
		z-index: @zindex-navbar - 1; //supporting multiple navbar
	}

	.static-sidebar-wrapper {
		width: 0 !important;
	}
}

#horizontal-navbar {
	.navbar-nav {

		li:hover > ul {
		  display: block;
		}

		> li:hover > a {
			i {background: transparent;}
		}
		ul {
			padding: 5px 0;
			border: none;
			border-radius: 0px;
			z-index: @zindex-navbar-fixed - 2;

			li {
				> a {
					//Dropdowns
					padding: 6px 25px 6px 20px;
				}
			}
		}
		li {
			a {
				text-decoration: none;
				z-index: @zindex-navbar-fixed - 1;
			}
			&.active>a:before {
				width: 0px; height: 0px;
				border-style: solid;
				border-width: 0 9px 9px 9px;
				border-color: transparent transparent @wf_gray_light transparent;

				content: "";
				position: absolute;
				bottom: 0px; left: 50%; margin-left: -9px;
			}
		}
		i {margin-right: 8px;}


		//Dropdowns
		&.large-icons-nav>ul>li>ul {
			// margin-top: -40px;
			text-align: left;
		}
	}

	&:not(.large-icons-nav) {
		.badge {
			position: inherit;
			left: 0;
			right: 0;
			margin-left: 5px;
		}
		.label {
			top: auto;
			position: inherit;
			left: auto;
			margin-left: 10px;
		}
	}
	.badge {
		left: 50%;
		margin-left: 10px;
		top: 10px;
	}
	.label {
		top: 10px;
		position: absolute;
		left: 50%;
		margin-left: 10px;
		line-height: @line-height-base;
	}
}


@media (max-width: @screen-xs-max) {
	#horizontal-navbar .navbar-nav {
		ul {
			width: 100%;
		}
	}
	#horizontal-navbar .navbar-nav li {
		&.active a:before {border-color: transparent !important;} // Removes arrow in small screen
	}
}

nav.navbar {
	.navbar-toggle {
		&, &:hover, &:focus {
			background: transparent;
			border:none;
		}
	}
}

@media (min-width: @screen-sm) {
	// Large Icon Nav
	#horizontal-navbar.large-icons-nav .navbar-nav {
		margin-bottom: 0;
		>li {
			min-width: 120px;
			> a > i {
				font-size: 24px;
				display: block;
				text-align: center;
				margin-bottom: 15px;
				margin-right: 0;
			}
			>a>span {
				display: block;
				text-align: center;
				//margin-top: 12px;
				text-transform: uppercase;
				letter-spacing: 0.1em;
				font-size: @font-size-mini;
			}
		}
	}
}

.large-icons-nav #top-nav > li > ul {
	  top: 85px;
}
@media (max-width: 768px) {
	#top-nav li > ul {
		position: absolute !important;
		top: 50px !important;
		padding-left: 0px !important;
	}
}
#top-nav {
	li > ul {
		display: none;
		position: absolute;
		top: 50px;
		left: 0;
		padding-left: 0;
		min-width: 200px;
	}
	li > ul li {
		list-style-type: none;
	}
	li > ul li ul{
		top:0px !important;
		left: 100%;
	}
	li > ul li a {
		width: 100%;
		display: inline-block;
	}
	li > ul li a:hover {
		text-decoration: none;
	}
	li:hover > ul {
		display: block;
	}
}
#horizontal-navbar .navbar-nav li.active a:before {
	display: none;
}

/*Fixed*/

.fixed-layout {
	background: @wf_gray_lighter;
	margin: 0 5%;

	header, #wrapper, #headernav {
		max-width: @boxed-width;
		width: 100%;
		margin: 0 auto;
	}
	header {
		.navbar-brand {display: block;}
	}
}


/* Page load transition */

.mainview-animation {
	position: relative;
}
.mainview-animation.ng-enter {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
}

.mainview-animation.ng-leave{
	display: none;
}


// .mainview-animation.ng-enter{
// 	opacity: 0;
// }

// .mainview-animation.ng-enter.ng-enter-active {
// 	opacity: 1;
// }


/* Full Page Error */
.error-fullpage {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	padding-top: 10% !important;

	font-size: 14px;
	a, & {color: #fff;}

	.overlay-on-image("../img/errorbg.jpg", rgba(0,0,0,0.7));
	.error-heading {font-size: 72px;}

}

.error-500 {

}

.error-404 {

}

/* Page content animations */
.animated-content {
	visibility: hidden;
}
