@import "../font-awesome-5/less/fontawesome.less";
@import "~font-awesome/less/variables.less";
@import "bootstrap/mixins.less";
@panelPadding: 20px;

.fa-icon(@content) {
	content: @content;
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome; // shortening font declaration
	font-size: inherit; // can't have font-size inherit on line above, so need to override
	text-rendering: auto; // optimizelegibility throws things off #1094
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.fa-icon-before(@content) {
	&:before {
		.fa-icon(@content);
	}
}


.fitInPaddedWrapper()
{
	margin-left: -@panelPadding;
	margin-right: -@panelPadding;
	margin-bottom: -@panelPadding;
	padding: @panelPadding;
}

.fitInModal()
{
	margin-top: -@panelPadding;
	margin-left: -@panelPadding;
	margin-right: -@panelPadding;
	margin-bottom: -@panelPadding;
	padding: @panelPadding;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.gradient (@startColor: #eee, @endColor: white) {
	background-color: @startColor;
	background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
	background: -webkit-linear-gradient(top, @startColor, @endColor);
	background: -moz-linear-gradient(top, @startColor, @endColor);
	background: -ms-linear-gradient(top, @startColor, @endColor);
	background: -o-linear-gradient(top, @startColor, @endColor);
}

.spinner(@size: 24px, @color: "dark", @borderWidthFactor: 8) // @color can be "dark", "light" or a color
{
	position: relative;
	color: transparent;

	&::before {
		content: '';
		border-radius: 50%;
		display: inline-block;
		width: @size;
		height: @size;
		animation: spin .8s infinite linear;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: @size / -2;
		margin-left: @size / -2;

		& when (@color = "dark") {
			border: (@size / @borderWidthFactor) solid rgba(0, 0, 0, 0.06);
			border-top-color: rgba(0, 0, 0, 0.28);
		}

		& when (@color = "light") {
			border: (@size / @borderWidthFactor) solid rgba(255, 255, 255, 0.2);
			border-top-color: rgba(255, 255, 255, 0.7);
		}

		& when not (@color = "dark") {
			& when not (@color = "light") {
				border: (@size / @borderWidthFactor) solid fade(@color, 20%);
				border-top-color: @color;
			}
		}
	}
	> * { visibility: hidden; }
}

.spinner-standalone(@size: 24px, @color: "dark") // @color can be "dark", "light" or a color
{
	&::before {
		content: '';
		border-radius: 50%;
		display: inline-block;
		width: @size;
		height: @size;
		animation: spin .8s infinite linear;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: @size / -2;
		margin-left: @size / -2;

		& when (@color = "dark") {
			border: (@size / 8) solid rgba(0, 0, 0, 0.06);
			border-top-color: rgba(0, 0, 0, 0.28);
		}

		& when (@color = "light") {
			border: (@size / 8) solid rgba(255, 255, 255, 0.2);
			border-top-color: rgba(255, 255, 255, 0.7);
		}

		& when not (@color = "dark") {
			& when not (@color = "light") {
				border: (@size / 8) solid fade(@color, 20%);
				border-top-color: @color;
			}
		}
	}
}

.placeholder(@color) {
	&::-webkit-input-placeholder {
		color: @color;
	}
	&:-moz-placeholder {
		color: @color;
	}
	&::-moz-placeholder {
		color: @color;
	}
	&:-ms-input-placeholder {
		color: @color;
	}
}

.transition(...) {
  -webkit-transition: @arguments;
  -moz-transition: @arguments;
  -o-transition: @arguments;
  transition: @arguments;
}

.asLink() {
	color: @link-color;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.popoverBoxShadow() {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.18), 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
}


.fadeBottom(@height: 24px, @gutter: 0px) {
	position: relative;
	overflow: hidden;

	&:before {
		content: '';
		display: block;
		height: @gutter;
		width: auto;
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		background: #fff;
		z-index: 9;
	}

	&:after {
		content: '';
		display: block;
		height: @height;
		width: auto;
		position: absolute;
		right: 0;
		bottom: @gutter;
		left: 0;
		z-index: 10;
		
		background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );

	}
}

.for(@list, @code) {
	& {
		.loop(@i:1) when (@i =< length(@list)) {
			@value: extract(@list, @i);

			@code();

			.loop(@i + 1);
		}

		.loop();
	}
}
