wf-item-statistics {
	display: block;
	> div {
		min-width: 140px;
		min-height: 50px;

		.total-item {
			.total-value {
				&.inconsistent-data {
					&[title] {
						position: relative;
					
						&:before,
						&:after {
							visibility: hidden;
							opacity: 0;
							pointer-events: none;
							transition: .1s;

							right: 100%;
							top: 60%;
							transform: translateY(-50%);
						}
						
						&:before {
							content: attr(title);
							position: absolute;
							padding: 5px 8px;
							border-radius: 2px;
							background-color: fade(@wf_black_darker, 90%);
							max-height: 65px;
							width: 225px;
							text-align: center;
							font-size: 11px;
							color: #fff;

							margin-right: 5px;
						}
						
						&:after {
							content: '';
							position: absolute;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 5px 5px 5px 0;
							border-color: transparent fade(@wf_black_darker, 90%) transparent transparent;

							transform: rotate(-180deg);
							margin-right: 0px;
							margin-top: -5px;
						}
					
						&:hover:before,
						&:hover:after {
							visibility: visible;
							opacity: 1;
							transition: .15s;
						}
					}//end of: &[data-original-title] - Tooltip
				}
			}
			
			.item-statistics-info-message {
				display: flex;
				flex-direction: column;
				justify-content: center;
				max-width: 80px;
				margin-bottom: 5px;
				cursor: default;

				.main-text {
					font-size: 12px;
					font-weight: 500;
					// color: @wf_black;
					
					i {
						margin-left: 2px;
						font-size: 11px;
						color: @wf_blue;
					}
				}
			}
		}


		&.equalWidths {
			min-height: 30px;

			.totals {
				display: block;
			}

			.total-item {
				min-width: 50px;
			}
		}

		&.smallSize {
			.total-item {
				padding: 0 10px;

				.total-value {
					font-size: 13px;
					font-weight: 600;
				}
				
				.total-title {
					font-weight: 400;
					font-size: 11px;
					padding-bottom: 2px;
				}
			}
		}
	}

	.totals {
		text-align: right;
		display: inline-block;

		.total-item {
			display: inline-block;
			text-align: center;
			padding: 10px 30px;
			min-width: 140px;

			.total-value {
				font-size: 18px;
				font-weight: 500;
			}

			.total-title {
				font-weight: bold;
				padding-bottom: 2px;
			}
			
			.total-subTitle {
				color: @wf_gray;
			}
		}
	}
}