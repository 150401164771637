@import "wf-animations.less";

wf-work-item {
	position: relative;
	height: 100%;
	width: 100%;
	display: block;

	.wf-work-item-wrapper {
		.wf-work-item-top {
			background-color: white;
			border-radius: 3px;
			box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 -1px 0px 0 rgba(0, 0, 0, 0.03);

			.wf-work-item-header {
				padding: 25px 25px 15px 25px;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-between;

				.wf-work-item-image {
					order: 1;
					margin-right: 15px;
					display: flex;
					flex-shrink: 0;
					align-items: center;
					justify-content: center;
					font-size: 20px;
				}

				.wf-work-item-content-wrapper {
					order: 2;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					flex-wrap: nowrap;
					flex-basis: 100%;

					.wf-work-item-content {
						order: 1;
						display: flex;
						flex-direction: column;
						justify-content: center;
						flex-basis: 80%;
						// flex-shrink: 0;
						
						.wf-work-item-textual-content {
							
							.wf-work-item-numbering {
								.wf-item-numbering(14, 500);
								height: 23px;
								top: -2px;
								color: @wf_gray_text_color;
								background-color: @wf_gray_light;
							}
							
							.wf-work-item-title, .wf-work-item-description {
								font-size: 20px;
								font-weight: 500;
								max-width: 740px;
								color: @wf_gray_text_color;
							}

							.wf-work-item-description {
								position: relative;
								top: -1px;
								font-size: 14px;
								font-weight: 400;
								max-width: 740px;
								color: @wf_gray_text_color;
							}

							// .wf-work-item-description {
							// 	max-width: 740px;
							//	white-space: nowrap;
							//	text-overflow: ellipsis;
							//	overflow: hidden;
							// }
	
							>.wf-work-item-title + .wf-work-item-description {
								display: block;
								font-size: 16px;
								color: @wf_gray_dark;
							}
						}

						.wf-work-item-attachments {
							.wf-work-item-attachments(@wf_blue_dark);
						}
					}
	
					.wf-work-item-requirements {
						order: 2;
						flex-basis: 20%;
						margin-left: 20px;
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;

						.requirement-indicator {
							background-color: @wf_gray;
							border: 1px solid @wf_gray_dark;
							font-weight: 600;
							text-transform: uppercase;
							border-radius: 3px;
							color: white;
							padding: 5px 10px;
							// margin: 0px 5px;
	
							&:hover {
								cursor: pointer;
							}
	
							&.required {
								background-color: @wf_red;
								border-color: @wf_red_dark;
							}
	
							&.fulfilled {
								background-color: @wf_green;
								border-color: @wf_green_dark;
							}
						}
					}
				}
			}
	
			.wf-work-item-body {

			}
		}
		.wf-work-item-bottom {

		}
	}

	.wf-work-item-side-actions {
		order: 3;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		min-width: 90px;
		flex-shrink: 0;
		justify-content: flex-end;
		margin-left: 10px;
		margin-top: 5px;

		.side-action {
			margin-left: 10px;
			.wf-circle-action-button(40, 40, 18);
		}

		.analyze-running-indicator {
			position: relative;
			top: 0px;

			.spinner {
				.spinner(20px);
				display: inline-block;
				width: 20px;
				height: 20px;
				position: relative;
				top: 5px;
			}

			span {
				display: inline-block;
				font-weight: 500;
				padding-left: 4px;
				padding-right: 25px;
				color: #a7a7a7;
			}
		}
	}
}

wf-inline-answering {
	@userImgWidth: 40;
	@userImgHeight: 40;
	@formControlLabel: 15;
	@fieldHeight: 35;
	@distanceBetweenFields: 10;

	display: flex;
	width: 100%;

	.wf-inline-answering-wrapper {
		padding: 15px 25px 25px 25px;
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		
		&.answer-module-selected {
			// padding-top: 13px;
			// .user-icon {
			// 	margin-top: 20px;
			// }

			// .answering-form { 
			// 	.answer-modules-toggles {
			// 		top: -32px; // answer-module-toggler height
			// 	}
			// }

			// .wf-work-item-side-actions {
			// 	margin-top: 20px;
			// }
		}

		&.multiple-answer-modules.answer-module-selected{
			// margin-top: 32px; // answer-module-toggler height
		}

		&.not-answered {
			.user-icon {
				&:after {
					animation: slideRightLeft 1s ease-in-out infinite;
				}
			}
		}

		&.offsetUserIcon {
			.user-icon {
				margin-top: 18px;
			}
		}

		.user-icon {
			position: relative;
			.wf-user-icon(@userImgWidth, @userImgWidth, @wf_blue_dark);
			// margin-top: 3px;
			margin-right: 15px;
			
			.user-img {
				// animation: pulseBoxShadowBlue 2.5s infinite;
				position: relative;
			}

			&:before {
				content: '\f0da';
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				font-size: 30px;
				color: @wf_blue_dark;
				position: absolute;
				right: -7px;
				top: -1px;

				// animation: slideRightLeft 1s ease-in-out infinite;
			}
		}

		.answering-form {
			// border: 1px dashed @wf_green;
			width: 100%;
			position: relative;
			
			.answer-modules-toggles {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				position: relative;
				margin-top: 4px;
				margin-bottom: 8px;

				.answer-module-toggler-header {
					padding: 8px 5px 0 0;
					font-weight: 500;
					font-size: 12px;
				}
				
				.answer-module-toggler {
					margin: 3px;
					.wf-work-item-secondary-button(12, 500, @wf_blue_dark, @wf_gray_hover, fade(@wf_blue_dark, 10%), 5, 8);
				}
			}

			.selected-answer-module {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-between;
				align-items: flex-start;
				width: 100%;
				min-height: @fieldHeight * 1px;

				>.picker-wrapper {
					flex-basis: 80% !important;
				}

				>.form-wrapper, >.picker-wrapper {
					margin-right: 15px;
				}

				.form-wrapper {
					order: 1;
					// border: 1px dashed fade(@wf_green, 30%);

					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: space-between;
					flex-basis: 80%;
					flex-shrink: 0;

					.question-answers-btn-group {
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: flex-start;
						flex-wrap: nowrap;
						
						
						.btn {
							.wf-work-item-primary-button(@wf_blue);

							&.loading {
								background-color: @wf_blue !important;
								&:before {
									border-top-color: rgba(255, 255, 255, 0.5);
								}
							}
							height: @fieldHeight * 1px;
							text-transform: uppercase;
						}
					}

					.form-buttons {
						margin-left: @distanceBetweenFields * 1px;
					}
				
					.wf-form-wrapper {
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: flex-start;
						flex-wrap: nowrap;
						flex-basis: 100%;

						wf-form {
							form {
								.location-search {
									padding-bottom: 0;
								}
							}
						}
					}
				}

				.form-wrapper + .picker-wrapper {
					margin-top: @formControlLabel * 1px;
				}

				.picker-wrapper {
					order: 1;
					// border: 1px dashed fade(@wf_green, 30%);
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: space-between;
					flex-basis: 20%;
					flex-shrink: 0;
				}

				.answer-module-buttons {
					order: 2;
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: space-between;
					flex-basis: 20%;
					flex-shrink: 0;

				}
				
				.form-buttons {
					.btn {
						.wf-work-item-primary-button(@wf_blue);
					}
				}

				.toggle-buttons {
					.btn {
						.wf-work-item-secondary-button(12, 500, @wf_blue_dark, @wf_gray_hover, fade(@wf_blue_dark, 10%), 5, 8);
						padding: 9px 16px;
						i {
							margin-right: 10px;
						}
					}
				}

				.form-buttons, .toggle-buttons {
					display: flex;
					align-items: flex-start;
					margin-top: (@formControlLabel * 1px) + 5;
					 
					button {
						height: 35px;
						min-height: 35px;
						margin-top: 4px;
					}
				}

				.or-divider {
					margin-top: (@formControlLabel * 1px) + 5;
					margin-left: 20px;

					display: flex;
					align-items: center;
					height: 32px;

					span {
						color: @wf_black_light;
						text-transform: lowercase;
						font-weight: 500;
						font-size: 14px;
					}
				}
			}
		}
	}
}

wf-answering-feed {
	@feedItemPadding: 2.5;
	display: block;

	> div {
		padding: 20px 15px 25px 25px;
		position: relative;
		height: 100%;
	}

	.wf-answering-feed-timeline {
		position: absolute;
		top: 35px;
		left: 45px;
		right: 25px;
		bottom: 50px;
		
		border-left: 1px solid #e0e0e0;
		z-index: 0;
	}

	.wf-answering-feed-items {
		position: relative;
		padding: 0 0 0 @feedItemPadding * 1px;
	}

	.items-toggler-wrapper {
		background-color: #F6F6F6;
		padding-top: 15px;
		position: relative;
		
		.items-toggler {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			padding: 7.5px 5px;
			border-radius: 3px;
			
			&:hover {
				cursor: pointer;
				background-color: darken(#F6F6F6, 3%);

				.expand-collapse-button {
					background-color: darken(#F6F6F6, 3%) !important;
				}
			}
	
			.expand-collapse-button {
				.wf-circle-action-button(35, 35, 20);
				background-color: #F6F6F6;
				color: @wf_blue;
				margin-right: 15px + @feedItemPadding * 1px !important;

				i {
					transition: .5s;
				}

				&.items-expanded {
					i {
						transform: rotate(180deg);
					}
				}
			}
	
			.expand-collapse-text {
				span {
					font-size: 14px;
					font-weight: 500;
					color: @wf_blue;
				}
			}
		}
	}

}

wf-answering-feed-item {
	@userImgWidth: 35;
	@userImgHeight: 35;

	display: flex;

	&:last-child {
		.wf-answering-feed-item-wrapper {
			.timeline-user-icon {
				background-color: #F6F6F6;
			}
		}
	}

	&:not(:last-child) {
		.feed-item-wrapper {
			margin-bottom: 15px;
		}
	}

	.wf-answering-feed-item-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		width: 100%;
		
		&:hover {
			.user-icon.timeline-user-icon {
				.user-img {
					transform: scale(1.1);
					transform-origin: center center;
					transition: transform .1s;
				}
			}
		}

		.user-icon {
			// display: flex;
			// margin-top: 18px;

			&.timeline-user-icon {
				margin-top: 10px;
				margin-right: 5px;
				// flex-basis: 40px;
				.user-img { background-color: @wf_gray; }
				.wf-user-icon(@userImgWidth, @userImgWidth);
			}
		}
	
		.feed-item-wrapper {
			// display: flex;
			// flex-direction: row;
			width: 100%;
			border: 1px solid transparent;
			border-radius: 3px;
			min-height: 57px;
			// border: 1px dashed green;

			&:hover {
				background-color: #fdfdfd;

				wf-item-component {
					.wf-item-component {
						.container {
							.image {
								.wf-image { background-color: #fdfdfd; }
							}
						}
					}
				}
			}

			&.highlight-item {
				background-color: white;
				border: 1px solid #ededed;

				wf-item-component {
					.wf-item-component {
						.container {
							.image {
								.wf-image { background-color: white; }
							}
						}
					}
				}
			}

			wf-item-component.wf-answering-feed-item {
				position: relative;
				width: 100%;
				padding: 10px 10px 0px 10px;
				border-radius: 3px;

				&.hide-item-image {
					>.wf-item-component {
						>.container {
							>.image { display: none; }
						}
					}
				}

				>.wf-item-component {
					>.container {
						align-items: flex-start;
						>.image {
							width: 35px;
							height: 35px;
							border-radius: 3px;
							background-color: fade(@wf_gray, 10%);

							.wf-image {
								display: flex;
								justify-content: center;
								align-items: center;
								background-color: fade(@wf_gray, 10%);
								border-radius: 3px;
								color: darken(@wf_gray, 40%);
							}
						}

						.item-tools {
							position: absolute;
							top: 7px;
							right: 10px;

							.wf-circle-action-button(40, 40, 18);
							margin-right: 0 !important;
						}

						.content-wrapper {
							.content {
								flex-direction: column;
	
								.inner-content {
									.headers {
										margin-bottom: 0;
	
										.wf-headerText {
											font-size: 16px;
											color: @wf_gray_text_color;
											padding: 0;
	
											a {
												display: flex;
												align-items: center;
											}
	
											.measure-answer-year {
												color: @wf_gray_dark;
												padding-right: 5px;
											}
										}
	
										.wf-subHeader {
											font-size: 12px;
											font-weight: 400;
											padding-top: 2px;
										}
	
										.wf-bodyText {
											font-size: 14px;
											max-width: 600px; //temp fix
											padding-top: 0;
										}
									}
								}
							}
							.side-actions { display: none; }
						}
					}
				}
			}

			wf-creator-time { 
				&:not(:empty) {
					margin: 0 10px 10px 10px;
				}
				display: block;
				
				.wf-creator-time(16, 12, @wf_gray_dark); 
				.user-icon {
					margin-right: 5px;
				}
			}

			.feed-item-attachments {
				wf-answering-feed-attachments {
					padding-top: 30px;
				}

				.inline-answering-wrapper {
					display: flex;
					flex-direction: row;
					padding-top: 10px;
					padding-bottom: 30px;
					margin: 0 10px;

					wf-inline-answering {
						.wf-inline-answering-wrapper {
							padding: 0;
						}
					}

					.wf-work-item-side-actions {
						.side-action {
							margin-top: 10px;
						}
					}
				}
			}
		}
	}
}

wf-answering-feed-attachments {
	.wf-answering-feed-attachments-wrapper {
		padding: 0 10px;

		.attached-info-label {
			font-size: 12px;
			font-weight: 600;

			margin: 20px 0 5px 0;
		}

		.attached-info-items {
			wf-item-component.wf-answering-feed-attachments {
				position: relative;
				padding: 10px 0;
	
				&.hide-item-image {
					>.wf-item-component {
						>.container {
							>.image { display: none; }
						}
					}
				}
	
				&:last-child {
					>.wf-item-component {
						.content-wrapper {
							border-bottom: none;
						}
					}
				}
	
				>.wf-item-component {
					>.container {
						align-items: flex-start;
						>.image {
							width: 35px;
							height: 35px;
							border-radius: 3px;
							background-color: fade(@wf_gray, 10%);
	
							.wf-image {
								display: flex;
								justify-content: center;
								align-items: center;
								background-color: fade(@wf_gray, 10%);
								border-radius: 3px;
								color: darken(@wf_gray, 40%);
							}
						}
	
						.item-tools {
							position: absolute;
							top: 10px;
							right: 0;

							.wf-circle-action-button(40, 40, 18);
							margin-right: 0 !important;
						}
					}
	
					.content-wrapper {
						padding-bottom: 10px;
						// border-bottom: 1px solid #e0e0e0;
						.content {
							flex-direction: column;
	
							.inner-content {
								.headers {
									margin-bottom: 0;
	
									.wf-headerText {
										font-size: 16px;
										color: @wf_gray_text_color;
										padding: 0;
	
										a {
											display: flex;
											align-items: center;
										}
	
										.measure-answer-year {
											color: @wf_gray_dark;
											padding-right: 5px;
										}
									}
	
									.wf-subHeader {
										font-size: 12px;
										font-weight: 400;
										padding-top: 2px;
									}
	
									.wf-bodyText {
										font-size: 14px;
										max-width: 600px; //temp fix
									}
								}
							}
						}
						.side-actions { display: none; }
					}
				}

				wf-creator-time { 
					.wf-creator-time(16, 12, @wf_gray_dark); 
					.user-icon {
						margin-right: 5px;
					}
				}
			}
		}

		.items-toggler-wrapper {
			background-color: transparent !important;
			padding: 0 !important;

			.items-toggler {
				padding: 0 !important;
				margin-bottom: 10px;
				&:hover {
					cursor: pointer;
					background-color: transparent !important;
				}
				.expand-collapse-button {
					border: none;
					margin-right: 5px !important;
					width: 30px;
					height: 30px;
					background-color: transparent !important;
				}
			}
		}
	}
}


wf-creator-time-bubble {
	display: inline-block;
	position: relative;
	width: 35px;
	height: 35px;
	cursor: default;
	
	> div {
		// transition: transform .05s;
		
		&:hover {
			transform: scale(1.1);
		}
	}

	.user-image {
		width: 35px;
		height: 35px;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-size: cover;
		border: 1px solid white;
		background-color: #bdc3c7;
	}

	.clock {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		position: absolute;
		bottom: 0;
		right: 0;
		background: #fff;
		color: #777;
		line-height: 10px;

		i {
			position: relative;
			top: -1px;
			left: -1px;
			text-shadow: 0 0 1px white, 0 0 1px white, 0 0 1px white, 0 0 1px white;
		}
	}
}