@import "wf-mixins.less";
@import "wf-variables.less";

.wf-bodyText {
	white-space: pre-wrap;
}

.wf-activityDates {
	padding-bottom: 10px;
	display: block;
	font-weight: bold;
	margin-top: -6px;
	color: #7775;
}

.bg-lightgray {
	background-color: #fff;
}

.fulfillment {
	.fulfillment-true {
		.fa-icon-before(@fa-var-check);
		color: #80B51A;
	}

	.fulfillment-false {
		.fa-icon-before(@fa-var-paper-plane);
		color: #C9C9C9;
	}

	.fulfillment-overdue {
		.fa-icon-before(@fa-var-warning);
		color: #F2772E;
	}
}

.subListInBox-gray {
	.fitInPaddedWrapper();
	background: #fff no-repeat center center;
	border-top: 1px solid #e9e9e9;
	margin-top: 23px;

	&.loading {
		height: 300px;
		background-repeat: no-repeat;
		background-position: center center;
		background-image: url("../img/loaders/spinner_subListInBox-gray.gif");

		> * {
			visibility: hidden;
		}
	}
}

.bgImage {
	font-size: 30px;
	text-align: center;
	color: #AFAFAF;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-position: center center;
	background-size: cover;
	// margin-top: 5px;
}

[isotope-container] {
	// min-height: 1000px;
}

wf-collection {
	display: block;
	clear: both;
	/*border: 1px solid magenta;*/
	margin: 1px;
	/*position: relative;*/

	&.loading {
		min-height: 200px;
		background-repeat: no-repeat;
		background-position: center center;
		background-image: url("../img/loaders/spinner_btn-default.gif");
	}

	.fullscreen-view {
		/*position: absolute;
		top: 0;
		bottom: 0;*/
		width: 100%;
		height: 100%;
		min-height: 100px;
		background: #ddd;
	}

	> .toolbar {
		padding-bottom: 16px;
	}

	.isotope-grid-sizer {
		width: 8.333%;
	}

	&.grid {
		> .inner > .list {
			margin-left: -9px;
			margin-right: -8px;

			> .wf-collectionGridItem {
				display: block;
				float: left;
				/*position: absolute;*/
				width: 33.333%;
				padding: 0;
				/*transition: width ease-out .5s, height ease-out .5s;*/

				&.fixedHeight {
					> div {
						position: relative;

						> panel {
							position: absolute;
							width: auto;
							height: auto;
							top: 0;
							left: 9px;
							right: 8px;
							bottom: 17px;

							> .panel {
								height: 100%;
								margin-bottom: 0;
								overflow: visible;
								// overflow-y: auto;

								> .panel-body {
									position: static;
								}
							}
						}
					}
				}

				&.autoHeight {
					padding: 0 8px 17px 9px;

					> div {
						position: relative;

						> panel > .panel {
							margin-bottom: 0;
						}
					}
				}

				.tools.admin {
					position: absolute;
					display: none;
					width: 135px;
					height: 38px;
					z-index: 1;
					background: #fff;
					padding: 2px;
					box-shadow: 0 0 2px rgba(0,0,0,0.3);
					margin-top: -24px;

					.settingsInfo {
						padding-left: 8px;
					}
				}

				&:hover {
					> .tools.admin {
						display: block;
					}
				}
			}
		}
	}


	.wf-collectionRow {
		/*border: 1px solid red;*/
		panel & {
			border-top: 1px solid #ddd;

			&:first-child {
				border-top: 0;
			}
		}

		&.ng-enter {
			transition: opacity linear 1s;
			opacity: 0;
		}

		&.ng-enter.ng-enter-active {
			opacity: 1;
		}

		&.ng-leave {
			transition: opacity linear 1s;
			opacity: 1;
		}

		&.ng-leave.ng-leave-active {
			opacity: 0;
		}
	}

	> ul {
		clear: both;

		> li {
			border-radius: 2px;

			> wf-item {
				padding: 8px;
			}

			&:hover {
				background: #fcfcfc;
			}
		}
	}
}


// [ng-controller] {
// 	&:before {
// 		content: attr(ng-controller);
// 		width: auto;
// 		height: 25px;
// 		position: absolute;
// 		display: block;
// 		z-index: 1;
// 		font-size: 24px;
// 		opacity: 0.6;
// 		color: magenta;
// 		background: rgb(255, 255, 255);
// 		line-height: 26px;
// 		padding: 0 2px;
// 		border-radius: 3px;
// 	}
// }

wf-item, .wfItem {
	display: block;
	cursor: default;

	&.asLink {
		cursor: pointer;
	}

	.adminMode &[wftemplate]:before {
		content: attr(wftemplate);
		width: auto;
		height: 25px;
		margin-top: 10px;
		position: absolute;
		display: block;
		z-index: 1;
		font-size: 24px;
		font-weight: bold;
		opacity: 0.8;
		color: #444;
		background: rgb(255, 255, 255);
		line-height: 26px;
		padding: 0 2px;
		border-radius: 3px;
	}

	wf-collection {
		display: block;
		margin-top: 1px;
		padding-top: 22px;
	}

	.child-content {
		clear: both;
		border: 1px dashed #ccc;
		margin-top: 8px;
		padding: 8px;
		padding-right: 8px;
		margin-right: -8px;
	}

	.body {
		> div {
			float: none;
			clear: none;
			overflow: hidden;
			width: auto;

			&.tools {
				float: right;
				padding-left: 50px;
				overflow: visible;
				text-align: right;
			}

		}

		.img {
			float: left;
			overflow: hidden;
			position: relative;
			width: 30%;
			max-width: 50px;
			min-width: 50px;
			min-height: 50px;
			margin-right: 10px;

			&.noImage {
				&:before {
					content: "";
					display: block;
					clear: none;
					float: left;
					overflow: hidden;
					width: 100%;
					padding-top: 100%; /* padding-top percentages are relative to the containing block's width. */
					background-color: rgba(0,0,0,0.1);
					box-shadow: 0 0 30px rgba(0, 0, 0, 0.02) inset;
				}
			}

			img {
				width: 80%;
				height: auto;
				float: left;
				margin-top: 2px;
			}

			&:after {
				content: "";
				display: block;
				float: left;
				clear: none;
				overflow: hidden;
				width: 20%;
				height: 1px;
			}
		}
	}

	.header {
		margin-top: 0;
		overflow: hidden;
	}

	.progress.fulfillment {
		margin-top: 16px;
		margin-bottom: 0;
	}

	.subItemsContainer {
		display: inline-block;
		padding-right: 4px;
		/*padding-bottom: 8px;*/
		padding-top: 8px;

		&.opened {
			width: 100%;
			clear: both;
		}

		wf-collection {
			width: 100%;
		}
	}
}

wf-item-tools {
	display: block;

	.dropdown-menu{
		position: fixed;
		width: 150px;
	}

	.dropdown-menu li > a {
		color: #707980;
		padding: 5px 15px;
	}

	&[discreet] {
		position: absolute;
		margin: -4px 0 0 -4px !important;

		&.open > div > .dropdown-menu {
			/*
				Since the dropdown-toggler element is wrapping the dropdown-menu element instead of being siblings,
				the .open class is applied on the wf-item-tools element. So visibility on the dropdown-menu is set with this rule instead.
			*/
			display: block;
		}

		/*z-index: 1;*/

		&.open {
			> div {
				z-index: 2;
			}
		}

		> div {
			position: absolute;
			z-index: 1;

			> div {
				width: 16px;
				height: 16px;
				background: #fff;
				border-radius: 10px;
				color: #666;
				box-shadow: 0 0 1px 1px rgba(0,0,0,.2);
				transition: transform .1s ease-out;
				cursor: pointer;
				font-family: Arial;
				position: relative;

				i {
					margin-left: 2px;
					display: inline-block;
					font-size: 13px;
				}

				&:hover {
					transform: scale(1.4);
				}
			}


			&.open {
				z-index: 2;

				> div {
					transform: scale(1.4);
					background: #555;
					color: #fff;
					box-shadow: 0 0 1px 1px rgba(255,255,255,.3);
				}
			}
		}
	}

	[data-preheader] {
		&:before {
			content: attr(data-preheader);
			display: block;
			padding-bottom: 5px;
			font-size: 9px;
		}
	}

	li i {

		&.fa, &.fas, &.glyphicon, &.ionicons {
			display: inline-block;
			width: 16px;
			margin-right: 9px;
			text-align: center;
			font-size: 14px;
			color: #6b7882;
		}
	}

	.large {
		font-size: 14px;

		&, * {
			color: #8e8e8e !important;
		}
	}

	li.dataRelation {
		&:first-child {
			border-top: 1px solid #eee;
		}

		a {
			position: relative;

			&:before {
				content: "\f02b";
				display: block;
				font: normal normal normal 14px/1 FontAwesome;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				position: absolute;
				top: 19px;
				left: 20px;
				font-size: 9px;
				z-index: 1;
				text-shadow: -1px -1px #FFF;
			}
		}
	}

}

.wf-preHeader, .wf-subHeader {
	// color: @wf_gray_dark;
	font-size: 11px;
}

.wf-preHeader {

}

.wf-headerText {

}

.wf-subHeader {

}

.wf-bodyText {
	white-space: pre-wrap;
}

.wf-creatorImage {
	width: 12px;
	height: 12px;
	display: inline-block;
	border-radius: 100%;
	background-size: cover;
	margin-bottom: -2px;
	margin-right: 1px;
}


wf-item.template3 {
	.body {
		.img {
			background-position: center center;
			background-size: 200%;
		}

		// &.objType15 {
		// 	.img {
		// 		background-image: url("../img/wfitems/71-10015.png");
		// 	}
		// }

		// &.objType18 {
		// 	.img {
		// 		background-image: url("../img/wfitems/71-10016.png");
		// 	}
		// }

		// &.objType50 {
		// 	.img {
		// 		background-image: url("../img/wfitems/71-10017.png");
		// 	}
		// }

		// &.objType44 {
		// 	.img {
		// 		background-image: url("../img/wfitems/71-10018.png");
		// 	}
		// }

		// &.objType31 {
		// 	.img {
		// 		background-image: url("../img/wfitems/71-10019.png");
		// 	}
		// }

		// &.objType25 {
		// 	.img {
		// 		background-image: url("../img/wfitems/71-10020.png");
		// 	}
		// }

		span.filetype {
			color: #aaa;
			font-size: 12px;
			white-space: nowrap;

			i.fa {
				position: relative;
				top: 1px;
				font-size: 13px;
				margin-left: 1px;
			}
		}

		.wf-headerText {
			font-weight: 400;
		}

		// a:hover {
		// 	.wf-headerText {
		// 		text-decoration: underline;
		// 	}
		// }

		.wf-bodyText {
			color: @gray-dark !important;
		}

		.wf-subHeader {
			margin-top: -2px;
			font-size: 11px;
			color: #aaa;
		}
	}

	wf-object-metadata{
		display: block;
	}
}

wf-item.template4, wf-item.template18, wf-item.template21 {
	/*min-height: 767px;*/

	wf-collection {
		/*min-height: 685px;*/
		margin-top: 30px;
		background: #fff no-repeat center center;
		.fitInPaddedWrapper();
		// border-top: 1px solid #e9e9e9;

		.wf-collectionGridItem.list-group-item {
			// &:hover {
			// 	background-color: rgba(0,0,0,0.03);
			// 	box-shadow: @panelPadding 0 rgba(0,0,0,0.03), -@panelPadding 0 rgba(0,0,0,0.03);
			// }

			> wf-item {
				cursor: default;
				// position: relative;
				// border-bottom: 1px solid #e0e0e0;
				// padding-bottom: 15px;
				// padding-top: 10px;
			}
		}

		&.loading {
			min-height: 200px;
			background-repeat: no-repeat;
			background-position: center center;
			background-image: url("../img/loaders/spinner_btn-default.gif");
		}
	}
}

.template6 {
	/* Structure: "KRÄVER ACTION" */

	> div > div {
		cursor: pointer;
		min-height: 40px;

		&:hover {
			background: #fafafa;
			box-shadow: 0 0 0 8px #fafafa;
		}
	}

	.number {
		font-size: 46px;
		padding-right: 20px;
		font-weight: bold;
		line-height: .9em;
	}

	p {
		float: none !important;
		clear: none;
		width: auto;
		overflow: hidden;
		margin-bottom: -10px;
		line-height: 1.2em;
	}

	.icon {
		font-size: 18px;
		padding: 0 8px;
	}

	.number.anyIsOverdue, .icon {
		color: #F97732;
	}

	p, .icon {
		padding-top: 12px;
	}
}

wf-item.template7, wf-item.template20, wf-item.template45, wf-item.template56 {
	position: relative;

	/*&:after {
		content: "";
		position: absolute;
		height: 1px;
		width: auto;
		left: 21px;
		right: 21px;
		bottom: 20px;
		background: #e0e0e0;
	}*/

	i:first-child {
		// margin-right: .35em;
		width: 15px;
	}

	a:hover {
		text-decoration: underline;
	}

	ul {
		li {
			padding: 1px 0 1px;
		}
	}

	ul.users {
		padding-top: 15px;

		li {
			position: relative;
			padding-left: 15px;

			i.userIcon {
				font-size: 13px;
				position: absolute;
				left: 0;
			}

			i.activatedIcon {
				color: #7DC36D;
				font-size: 7px;
				margin-left: 3px;
				top: -1px;
				position: relative;
			}

			.details {
				color: #aaa;
				font-size: 11px;

				div {
					float: left;
					padding-right: 5px;

					&:before {
						content: '\00B7';
						padding-right: 5px;
						font-weight: 800;
					}

					&:first-child:before {
						display: none;
					}

					&.mail {
						cursor: pointer;

						&:hover {
							text-decoration: underline;
						}

						&.failed {
							color: rgb(232, 64, 64);
							font-weight: bold;

							i {
								position: relative;
								padding-left: 3px;
								top: 1px;
							}
						}
					}
				}

			}
		}
	}

	ul.parents {
		padding-top: 25px;

		li {
			float: left;
			padding-right: 15px;
		}
	}

	ul.children {
		li {
			padding-bottom: 3px;
			display: block;

			&.highlight {
				transform: scale(1.2);
				transform-origin: left center;
				margin-left: -1px;

				a {
					font-weight: 500;
				}
			}

			&.notFulfilled * {
				// color: #AFAFAF;
			}
			&.fulfilled * {
				// color: #48C72B;
				color: #389621;
			}
			&.hasProgress * {
				// color: #48C72B;
				color: #B9950A;
			}
			&.overdue * {
				// color: #F97732;
				color: #B55E31;
			}

			a {
				color: #707980;
				display: block;
				float: left;
				padding: 0 10px 0 0;

				i {
					font-size: 12px;
				}
			}

			span.status {
				display: block;
				float: left;
				padding-top: 2px;

				span {
					// float: left;
					// display: block;
				}

				i {
					font-size: 14px;
					// display: block;
					// height: 16px;
					// width: 15px;
					// float: left;

					&.fulfilled {
						color: #48C72B;
					}
					&.notFulfilled {
						color: #AFAFAF;
						font-size: 12px;
					}
					&.overdue {
						color: #F97732;
					}
					&.hasProgress {
						color: #DBAE0A !important;
					}
					&.hasDueDate {
						color: #aaa !important;
					}
					&.specialRequirements {
						color: #ADA41A !important;
					}
					&.allSigned {
						color: #3498db !important;
					}

				}
			}
		}
	}

	a.addItem {
		display: inline-block;
		margin-top: 5px;

		i {
			position: relative;
			font-size: 13px;
			top: 1px;
		}

		// .fa-icon-before()

		// &.user {
		// 	i {
		// 		font-size: 18px;
		// 		top: 2px;
		// 	}
		// }
	}

	wf-item-tools {
		button {
			padding-left: 0;
			padding-right: 0;
			text-align: center;
			width: 34px;
		}
	}
}

wf-item.template11, wf-item.template53 {
	padding-bottom: 50px;
	min-height: 418px;

	&:hover {
		.bgImage {
			// box-sizing: content-box;
			// margin: 0 10px;
		}
	}

	.img {
		height: 200px;
		width: auto;
		margin: -@panelPadding;
		margin-bottom: @panelPadding;
		overflow: hidden;
		position: relative;

		.bgImage {
			// transition-duration: .2s;
		}

		&.noImage {
			&:before {
				content: "";
				display: block;
				clear: none;
				overflow: hidden;
				width: 100%;
				height: 100%;
				background: url("http://lorempixel.com/400/200/abstract/") no-repeat center center;
			}
		}

		img {
			width: 100%;
		}
	}

	.fade-bottom {
		position: absolute;
		width: auto;
		padding: 20px;
		left: 0;
		right: 0;
		bottom: 70px;
		top: 0;
	}

	.bottom {
		height: auto;
		padding-bottom: @panelPadding;
		position: absolute;
		right: @panelPadding;
		left: @panelPadding;
		bottom: 0;

		.bottom-body {
			float: none;
			clear: none;
			overflow: hidden;
		}
	}

	.guidance-btn {
		display: none;
	}
}

wf-item.template12 {
	/*cursor: default;
	position: relative;
	border-bottom: 1px solid #e0e0e0;
	padding-bottom: 15px;
	padding-top: 10px;*/

	.overdue {
		font-weight: bold;
		color: #F2772E;
	}

	.supplierCount {
		display: inline-block;
		// min-width: 10px;
		// text-align: right;

		&.loading:before {
			content: '...';
			display: inline-block;
			font-weight: normal;
		}
	}

	wf-item-tools {
		margin-left: 4px;
	}
}

wf-item.template13 {
	/*min-height: 767px;*/


	.btn-group {
		/*position: absolute;
		top: 90px;
		right: 20px;*/
		// padding: 20px 0;
	}

	wf-collection {
		/*min-height: 678px;*/
		margin-top: 30px;
		.fitInPaddedWrapper();
		background: #fff no-repeat center center;
		border-top: 1px solid #e9e9e9;

	// 	&.loading {
	// 		/*transition: background-image 0s ease;
	// 		background-image: url(../../assets/img/loaders/wf_green.gif);*/
	// 	}
	}
}

wf-item.template15 {
}

wf-item.template17 {
	button {
		min-width: 100px;
	}
}

wf-item.template23 {
	.influenceChildContent {
		min-height: 200px;

		&.loading {
			.spinner(22px);
		}
	}
}


wf-item.template24 {
	.alert {
		margin-top: 24px;

		h4 {
			margin-bottom: 0;
		}
	}
}

wf-item.template25 {
	padding-bottom: 50px;

	.img {
		height: 165px;
		width: auto;
		margin: -@panelPadding;
		margin-bottom: @panelPadding;
		overflow: hidden;

		&.noImage {
			position: relative;

			&:before {
				content: "";
				display: block;
				clear: none;
				overflow: hidden;
				width: 100%;
				height: 100%;
				// background: url("http://lorempixel.com/400/200/abstract/") no-repeat center center;

				.gradient(#667, #889);
			}

			.bgImage {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background-position: center center;
				// background-size: cover;
			}
		}

		img {
			width: 100%;
		}
	}

	.fade-bottom {
		position: absolute;
		width: auto;
		padding: @panelPadding;
		left: 0;
		right: 0;
		bottom: 70px;
		top: 0;
	}

	.bottom {
		height: auto;
		position: absolute;
		right: @panelPadding;
		left: @panelPadding;
		bottom: @panelPadding;

		.progress {
			height: 8px;
			box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
			margin-top: 4px;
			margin-bottom: 0;

			> div {
				box-shadow: inset 0 -1px 1px rgba(0,0,0,.15);
			}
		}
	}
}

wf-item.template48, wf-item.template32, wf-item.template41, wf-item.template58 {
	.modal & {
		.fitInModal();
		background-color: @wf_gray_light;
	}

	panel .panel-body > .loading {
		.spinner(22px);
		min-height: 300px;

		.badge-primary {
			font-size: 12px;
		}
	}

	.wf-headerText.level1 {
		font-size: 25px;
		font-weight: 600;
	}
}

wf-item.template42 {
	.container-fluid.loading {
		.spinner(22px);
		min-height: 300px;
	}
}

wf-item.template33, wf-item.template49 {
	.header {
		font-size: 21px;
	}
	wf-item .header { font-size: 16px; font-weight: bold; }
	wf-item wf-item .header { font-size: 14px; }
	wf-item wf-item wf-item .header { font-size: 12px; }
	wf-item wf-item wf-item wf-item .header { font-size: 11px; }
	wf-item wf-item wf-item wf-item wf-item .header { font-size: 10px; }

	//Margins to items in a standard
	margin-top: 20px;
	.template33{
		margin: 5px 0px 5px 0px;
	}

	.list-group-item {
		margin-left: 20px;
	}

	//Remove margins assigned from HTML style
	.list-group-item .template33 .container-fluid {
		// padding-left: 0px !important;
	}

	//Header style for measures created by Worldfavor
	.measuresCreatedByWf {
		font-size: 12px;
		font-style: italic;
		font-weight: 200;
		color: @wf_gray_dark;
	}
}

wf-item.template30, wf-item.template47 {
	.loading {
		.spinner(22px);

		> * {
			visibility: hidden;
		}
	}

	.main {
		transition: background-image 3s ease;

		&.loading {
			height: 300px;
			background: no-repeat center center;
			transition: background-image 0s ease;
			background-image: url(../../assets/img/loaders/wf_green.gif);

			> * {
				visibility: hidden;
			}
		}

		.stepHeader {
			font-weight: 200;
			font-size: 17px;

			span {
				font-weight: 400;
			}
		}

		.stepSubHeader {
			padding: 0;
			margin: 0;
			font-weight: 200;
			font-size: 15px;
		}


		.pickedCount {
			// color: #3F84ED;
			padding-top: 14px;
			font-weight: 600;
			// text-transform: uppercase;
			text-align: right;
			margin-bottom: -5px;
		}

		.largeCount {
			text-align: center;
			position: relative;

			&.first {
				border-bottom: 1px solid #eaeaea;
				padding-bottom: @panelPadding + 10;
				margin-bottom: 7px;

				.value {
					color: #EA762A;
				}

				&:after, &:before {
					top: 100%;
					left: 50%;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
				}

				&:after {
					border-color: rgba(255, 255, 255, 0);
					border-top-color: #fff;
					border-width: 12px;
					margin-left: -12px;
				}
				&:before {
					border-color: rgba(238, 238, 238, 0);
					border-top-color: #e0e0e0;
					border-width: 13px;
					margin-left: -13px;
				}
			}

			.value {
				font-size: 50px;
				padding-top: 25px;
				font-weight: 500;
				color: #7DBA75;
			}

			.valueLabel {
			}

			button {
				margin-top: 25px;
			}
		}

		.bottomWrapper {
			position: absolute;
			bottom: @panelPadding + 10;
			right: 0;
			left: 0;
			width: 100%;

			// button {
			// 	margin: auto;
			// }
		}

		.sdgList {
			position: absolute;
			top: 54px;
			bottom: 80px;
			right: 6px;
			left: @panelPadding;
			overflow: hidden;

			wf-item {
				padding-bottom: 20px;
				padding-right: @panelPadding;
			}

			.wf-bodyHeader {
				margin-bottom: 6px;
			}

			.wf-bodyText {
				color: #aaa;
				overflow: hidden;
				white-space: pre-wrap;
				// white-space: nowrap;
				// text-overflow: ellipsis;
			}
		}
	}
}

// wf-item.template37 {
// 	.subListInBox-gray {
// 		position: relative;

// 		&:before {
// 			content: "";
// 			display: block;
// 			position: absolute;
// 			width: 1px;
// 			height: 100%;
// 			top: 0;
// 			left: 50%;
// 			background: #e3e3e3;
// 		}
// 	}
// }

wf-item.template43, wf-item.template51 {
	.list-group-item {
		padding-top: 20px;
		padding-bottom: 20px;

		&:first-child {
			padding-top: 0;
		}
	}

	panel{
		padding-left: 0;
	}
}

wf-item.template46 {
	@greenColor: #90C66D;

	font-size: 12px;
	background: #fff;

	h3.areas {
		text-transform: uppercase;
		color: @greenColor;
		font-weight: bold;
	}

	.btn {
		text-transform: uppercase;
	}

	.areaHeader {
		font-size: 14px;
	}

	.colHeaders {
		color: @greenColor;
		text-transform: uppercase;
		padding: 20px 0;
		border-bottom: 1px solid #eee;
		font-size: 14px;
	}

	.badge {
		font-size: 16px;
		font-weight: bold;
		background-color: @greenColor;
		padding: 6px 8px 5px;
		border-radius: 30px;
	}

	.areas {
		.row.area {
			padding: 30px 0 20px;
			border-bottom: 1px solid #eee;
		}
	}

	.questions {
		.row.question {
			padding: 20px 0;
			border-bottom: 1px solid #eee;
		}
	}
}

wf-item.template63 {
	display: block;
	// height: 100px;
}

wf-chart {
	display: block;
	margin-bottom: -20px;

	p {
		text-align: center;
		font-size: 93%;
		padding-top: 6px;
		line-height: 1.3em;
	}

	div {
		margin: auto;
		width: 100px;
		position: relative;

		> span {
			display: block;
			text-align: center;
			position: absolute;
			font-weight: bold;
			width: 100%;
			top: 50%;
			margin-top: -20px;
			height: 40px;
			line-height: 40px;
			font-size: 17px;
		}

		canvas {
			width: 100% !important;
			height: 100% !important;
		}
	}
}

wf-toggle {
	display: inline-block;

	.saving {
		opacity: .5;
		transition-duration: .5s;
	}

	a {
		display: inline-block;

		span {
		}

		i.fa, i.ionicons {
			display: block;
			float: left;
			clear: none;
			width: 20px;
			font-size: 17px;
			position: relative;
			top: -2px;
			padding-right: 5px;
		}

		div {
			float: none;
			clear: none;
			width: auto;
			overflow: hidden;
			line-height: 1.3em;
		}
	}

}

.svgChartDirective {
	.svgChartDirective();
}

.svgChartDirective() {
	display: block;
	width: 100%;
	height: 100%;
}

wf-question-answering {
	display: block;

	&.view {
		&, * {
			// width: 100%;
			display: inline-block;
			text-align: center;
		}

		i {
			position: relative;
			top: 2px;
			padding-left: 5px;
		}

		wf-item-relations {
			position: absolute;
			display: block;
			top: 2px;
			right: 10px;
		}
	}

	wf-influence-viewer & {
		.btn-group {
			text-align: right;
			max-width: 350px;

			.btn {
				display: inline-block;
				float: none;
				max-width: 350px;
			}
		}
	}

	.localFulfillment {
		display: inline-block;
		width: 20px;
		height: 10px;
		position: relative;

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 3px;
			left: 12px;
			width: 8px;
			height: 8px;
			border-radius: 10px;
			transition-duration: .1s;
		}

		&.unfulfills:after {
			background-color: #e63c3c;
		}
		&.fulfills:after {
			background-color: #7dc36d;
		}
		&.fulfillsException:after {
			background-color: #f3bc00;
		}
	}

	.invisible-answer-available {
		margin-left: 1px;

		> div {
			cursor: pointer;
			position: relative;
			display: inline-block;
			padding: 6px 0;

			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				transition: background-color .3s;
				pointer-events: none;
			}

			&.loading {
				&, * {
					cursor: default;
				}

				&:after {
					background: rgba(255,255,255,0.85) url("../img/loaders/blueOnWhite_loop1_small.gif") no-repeat center center;
				}
			}

			i {
				font-size: 13px;
				margin-right: 2px;
			}

			span.as-link {
				font-size: 11px;
				.asLink();
				color: @wf_blue;
			}
		}
	}
}

wf-question-answering, wf-measure-answering {
	.localFulfillment {
		display: inline-block;
		width: 20px;
		height: 10px;
		position: relative;

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 3px;
			left: 12px;
			width: 8px;
			height: 8px;
			border-radius: 10px;
			transition-duration: .1s;
		}

		&.unfulfills:after {
			background-color: #e63c3c;
		}
		&.fulfills:after {
			background-color: #7dc36d;
		}
		&.fulfillsException:after {
			background-color: #f3bc00;
		}
	}
}

wf-parameter-answering {
	.parameter-value {
		.format-image {
			padding-top: 5px;

			img {
				width: 150px;
				height: auto;
			}
		}

		.format-location {
			padding-top: 5px;
		}
	}
}

.pickerController {
	.form {
		padding-bottom: 20px;
	}

	form {
		.inputsContainer {
			min-height: 100px;
		}
	}

	.btn.formToggler {
		i {
			font-size: 12px;
			position: relative;
			top: -1px;
			margin-left: 8px;
		}
	}

	.modal-body {
		padding-top: 0 !important;
	}

	.existingItems {
		margin-top: -1px;

		&.loading {
			min-height: 300px;
			.spinner(22px);
		}
	}

	.listHeader {
		font-weight: 500;
		font-size: 17px;
	}
}

.supplierPlatformController {
	&.loading {
		button {
			visibility: hidden;
		}

		.loader {
			overflow: hidden;
			height: 300px;
			background: url("/assets/img/loaders/spinner_mainBg.gif") center center no-repeat;

			> * {
				visibility: hidden;
			}
		}
	}
}

.supplierGroupController {
	dir-pagination-controls {
		display: block;
		margin-top: -15px !important;
		margin-bottom: 30px;
	}
}

.supplierCategoryController {
	.panel-filter {
		padding-top: 15px;
	}
	// dir-pagination-controls {
	// 	display: block;
	// 	margin-top: -15px !important;
	// 	margin-bottom: 30px;
	// }
}

.wfQuestionAnsweringHistory, .wfMeasureAnsweringHistory {
	display: block;

	.list-group {
		margin: 0;
	}
	.list-group-item {
		padding: 10px 0;
		// padding-left: 25px;
	}

	wf-item .body .img {
		min-width: 40px;
		width: 40px;
		margin-right: 0px;
		height: 40px;
		min-height: 40px;
		max-width: 40px;

		div {
			width: 30px;
			height: 30px;
			border-radius: 100%;
			background-size: cover;
		}
	}
}

.emptyList-message {
	text-align: center;
	padding: 50px 0;

	.circled {
		// Circle around icon
		width: 100px;
		margin: auto;
		height: 100px;
		border-radius: 100%;
		border: 2px solid rgba(166, 183, 185, 0.84);
		line-height: 100px;

		.icon {
			line-height: 100px;
		}
	}

	.icon {
		font-size: 70px;
		color: #A6B7B9;
	}

	.header {
		font-weight: bold;
		font-size: 15px;
		padding-bottom: 5px;
		// color: rgb(148, 169, 173);
	}

	.body {
		// padding-top: 10px;
		margin: auto;
		max-width: 400px;
		// color: rgb(148, 169, 173);
	}

	&.dashedBox {
		background-color: #fff;
		border: 1px dashed #D5D4D1;
	}

	&.large {
		padding: 70px 50px 80px;

		.header {
			font-size: 17px;
		}

		.body {
			font-size: 14px;
			max-width: 100%;
		}
	}
}

.supplierDemandDocumentController {
	@numberWidthFactor: 0.8;
	@numberWidth: 8.33333333% * @numberWidthFactor;

	&.useNewLayout {
		.questionCategory {
			font-weight: 500;
			font-size: 17px;
			color: #444;
		}

		.number {
			font-size: 14px;
		}
	}

	.number {
		width: @numberWidth;
		font-weight: bold;
		font-size: 15px;
		color: #a0aab0;
		min-height: 1px;
		clear: left;
	}

	.extra {
		width: 100% - @numberWidth;
	}

	.questionAndAnswer {
		// width: (68.33333333 - (8.33333333 * @numberWidthFactor)) + "%";
		// width: 59.333333%;
		width: 92.333333%;

		.answer {
			padding-left: 35px;
			min-width: 80px;
		}
	}

	.subQuestion {
		padding-left: 0;
		margin-left: -15px;
	}

	.docStatement {
		textarea {
			resize: none;
			height: 100px;
		}

		button {
			margin-right: 44px;
		}
	}

	.signAndVerify {
		.scrollthis {
			height: 150px;
		}

		.verificationDoc {
			a {
				display: inline-block;
				padding: 3px 0;
				margin-left: 1px;

				i {
					font-size: 14px;
					padding-right: 7px;
					color: #333;
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.bottomButtonBar {
		padding-top: 40px;
		min-height: 77px;
	}

	wf-item-tools {
		button {
			margin-right: 0 !important;
		}
	}

	.addInformation {
		margin-right: 29px;
	}

	.list-group {
		margin-bottom: 0;
	}

	.removeAction {
		margin-top: 4px;
		margin-left: 60px;
		display: inline-block;

		&:hover {
			text-decoration: underline;
		}
	}

	&.printMode {

		* {
			box-shadow: none;
			text-shadow: none;
			border-radius: 0;
		}

		.page-heading {
			padding-bottom: 0;
		}

		.objType71 {
			padding-left: 0 !important;
		}
		.subListInBox-gray {
			background: transparent;
			padding: 0 !important;
			margin: 0 !important;
		}

		.panel, .panel-body {
			padding: 0;
			background: transparent !important;
		}

		.createdAt {
			display: none;
		}

		.subQuestion {
			margin-left: -10px;
		}
	}
}

.guidance-btn {
	display: inline;
	margin-left: 5px;
	color: @wf_green;
	font-size: 14px;
	cursor: pointer;
	transition-duration: .1s;

	&:hover {
		color: @wf_green_dark;
	}
}

wf-measure-answering {
	display: block;

	.measure-answer {
		float: none;
		clear: none;
		overflow: hidden;
		// text-align: center;

		.value {
			// display: inline-block;
			white-space: pre-wrap;
			width: 80px;

			span { /* Year */
				// font-size: 90%;
				font-weight: normal;
				color: #aaa;
				display: block;
				font-size: 10px;
			}
		}

		.trend {
			// display: none;
			display: inline-block;
			visibility: hidden;
			padding-left: 20px;

			> div {
				display: inline-block;
				transition-duration: .4s;
				width: 17px;
				height: 17px;
				text-align: center;
			}

			&.up, &.down, &.same, {
				display: inline-block;
				visibility: visible;
			}

			&.up > div {
				transform: rotate(-40deg);
			}

			&.down > div {
				transform: rotate(40deg);
			}
		}
	}

	.measure-answer-add {
		float: right;
		clear: none;
		margin-left: 20px;
	}

	.localFulfillment {
		margin-top: 6px;
	}
}

.measure-answer-year {
	font-weight: normal;
	color: #aaa;
	padding-right: 10px;
}

.UpgradeController {
	.page-heading {
		padding-bottom: 0;
	}

	h1 {
		font-size: 32px;
		font-weight: 700;
		line-height: 34px;
		color: #2c3e50;
		padding-top: 18px;
		margin-bottom: 52px;
	}

	h3 {
		padding-bottom: 20px;
	}

	.banner {
		text-align: center;
		margin: -20px -20px 0;
		padding: 25px;

		-webkit-border-top-left-radius: 3px;
		-webkit-border-top-right-radius: 3px;
		-moz-border-radius-topleft: 3px;
		-moz-border-radius-topright: 3px;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}

	.subscriptions {
		h3 {
			margin: 0;
			padding: 0;
			padding-bottom: 8px;
			font-weight: 500;
			color: white;
		}

		i {
			color: white;
			opacity: 0.8;
		}

	}

	p + h4 {
		padding-top: 7px;
	}

	h4 {
		padding: 20px 0 4px;
		margin: 0;
	}

	ul.list-bullets {
		padding-left: 15px;

		li {
			padding: 4px 0;
		}
	}

	ul.list-icons {
		padding-top: 15px;
		font-size: 13px;

		li {
			padding: 5px 0 5px 21px;
			position: relative;

			i {
				display: block;
				width: 21px;
				position: absolute;
				top: 7px;
				left: 0;
			}

			span {
				display: block;
				float: none;
				clear: none;
				overflow: hidden;
			}

			p {
				font-size: 12px;
				color: rgb(150, 150, 150);
				margin: 0;
			}
		}
	}

	.addons {
		.banner {
			height: 200px;
			background-size: cover;
			background-position: center center;
		}
	}

	.divider {
		display: block;
		width: 100%;
		margin: 32px 0;
		border-top: 1px solid @wf_black;
		opacity: 0.2;
	}

	.standardsAndMore {
		h4 {
			padding-top: 0;
		}

		ul.list-icons {
			li {
				padding-left: 24px;

				i {
					width: 24px;
					font-size: 18px;
					top: 4px;
				}
			}
		}
	}

	.contact {
		padding-left: 50px;
		font-size: 13px;

		h5 {
			font-weight: 500;
			font-size: 14px;
			margin-bottom: 4px;
		}

		ul {
			li {
				padding: 1px 0;

				a {
					color: #777;

					&:hover {
						color: #333;
					}
				}

				i {
					width: 19px;

					&.mail {
						font-size: 11px;
						position: relative;
						top: -1px;
					}
					&.call {
						font-size: 14px;
					}
					&.speak {
						font-size: 13px;
					}
				}
			}
		}
	}

	.account-options {
		text-align: center;
		font-size: 14px;

		ul {
			text-align: center;
    		list-style: none;
			margin: 20px 0 0 -42px;


			li {
				margin-bottom: 8px;
				font-weight: 500;

				&:before {
					font-family: "FontAwesome";
					content: "\f00c";
					margin-right: 8px;
				}
			}
		}

		i {
			font-size: 50px;
			margin: 20px 0;
		}

		.manage {
			i {
				color: @wf_blue;
			}

			li::before {
				color: @wf_blue;
			}
		}

		.visualize {
			i {
				color: @wf_orange;
			}

			li::before {
				color: @wf_orange;
			}
		}

		.valuechain {
			i {
				color: @wf_green;
			}

			li::before {
				color: @wf_green;
			}
		}

		.account-options-body {
			height: 400px;
		}

		.panel-body {
			padding: 32px;
		}

		.btn-lg {
			display: block;
			width: 100%;
		}

		.highlight {
			// background-color: @wf_blue;
			// color: #FFF;
			// font-weight: 600;
			// font-size: 11px;
			// width: 120px;
			// padding: 8px 0;
			// background-color: rgba(255, 255, 255, 0);
			width: 201px;
			padding: 10px 0px 1px;
			position: absolute;
			top: 0px;
			left: 247px;
			height: 75px;
			border-bottom: 31px solid #3498db;
			border-left: 31px solid transparent;
			border-right: 31px solid transparent;
			transform: rotate(45deg);

			p {
				top: 42px;
    			position: relative;
    			font-size: 12px;
				color: #FFF;
				font-weight: 600;
			}
		}
	}

	.iso-image {
		background-color: @wf_black;
		height: 60px;
		width: 60px;
		display: inline-block;
		vertical-align: top;
		overflow: hidden;

		img {
			height: 60px;
			margin-left: -12px;
		}
	}

	.iso-text {
		width: 80%;
		display: inline-block;
		vertical-align: top;
		margin-left: 12px;

		p {
			margin-top: 8px;
			min-height: 32px;
		}

		h4 {
			padding: 0px;
		}
	}

	.iso-header {
		width: 50%;
		display: inline-block;
	}

	.iso-readmore {
		width: 49%;
		display: inline-block;
		text-align: right;
		color: @wf_blue;
		font-size: 14px;

		&:after {
			font-family: "FontAwesome";
			content: "\f08e";
			margin-left: 8px;
			font-size: 12px;
		}
	}

	.btn-primary {
		transition: background-color 500ms ease;
	}

	.btn-manage {
		background-color: @wf_blue;

		&:hover {
			background-color: @wf_blue_dark !important;
		}
	}

	.btn-visualize {
		background-color: @wf_orange;

		&:hover {
			background-color: @wf_orange_dark !important;
		}
	}

	.btn-valuechain {
		background-color: @wf_green;

		&:hover {
			background-color: @wf_green_dark !important;
		}
	}

	.panel {
		margin-top: 0px;
		transition: margin-top 200ms, box-shadow 300ms;

		&:hover {
			margin-top: -4px;
			box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.22), 0 -1px 0px 0 rgba(0, 0, 0, 0.03);
		}
	}
}

.DashboardController {
	h4 {
		font-weight: 600;
		font-size: 18px;
		line-height: 21px;
	}

	.dashboard-panel {
		.panel {
			height: 300px;

			h4 {
				padding: 0;
				margin: 0;
			}
		}
	}

	.panelItem {
		a.openStructure {
			opacity: 0.8;

			&:hover {
				opacity: 1;
				text-decoration: underline;
			}
		}

		.panel-body {
			padding: 30px;
		}

		.subItem {
			padding: 5px 0;

			&:hover .wf-headerText {
				text-decoration: underline;
			}
		}

	}

	.button-open {
		margin-top: 4px;
	}

	.sideBar {
		h4 {
			font-weight: 600;
			font-size: 15px;
			line-height: 19px;
		}

		.box {
			&:not(.box-organization) {
				border-top: 1px solid rgba(0,0,0,0.08);
				padding-top: 15px;
				margin-top: 18px;
			}
		}
	}

	.side-action {
		display: inline-block;
		padding: 3px 0 3px 20px;
		position: relative;
		top: -3px;

		i {
			margin-left: 5px;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	.box-organization {
		.wf-image {
			margin-top: 4px;
		}

		h4 {
			margin: 0;
			padding: 2px 0 5px;
		}
	}

	.box-users {
		h4 {
			margin: 0;
			margin-top: -2px;
			margin-bottom: 17px;
		}
	}

	.users {
		margin: 0 -2px;

		li {
			display: block;
			width: 11.1%;
			height: 32px;
			padding: 2px;
			margin: 0;
			float: left;

			div.user-photo {
				width: 100%;
				height: 100%;
				background-position: center center;
				background-size: cover;
				background-color: rgba(0,0,0,0.1);
				border-radius: 1.5px;
			}
		}
	}

	.box-promotions {
		h4 {
			margin-bottom: 10px;
			margin-top: 0;
		}

		.promoItem {
			display: block;
			border-radius: 2px;
			box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 -1px 0px 0 rgba(0, 0, 0, 0.03);
			color: white;
			text-shadow: 0 0px 3px rgba(0, 0, 0, 0.15);

			&:not(:last-child) {
				margin-bottom: 15px;
			}

			> div {
				border-radius: 2px;
				padding: 20px;
				transition-duration: .15s;
				box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) inset;
				background-color: rgba(236, 240, 241, 0.08)
			}

			&:hover {
				> div {
					transition-duration: .05s;
					box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1) inset;
					background-color: rgba(236, 240, 241, 0)
				}
			}

			.wf-headerText {
				font-size: 18px;
				font-weight: 400;
				padding-bottom: 8px;
				margin-top: -3px;
			}
		}
	}

	.box-events {
		.lineChart {
			padding-bottom: 20px;

		}

		.notificationItem {
			border-top: 0;
			border-color: rgba(0, 0, 0, 0.06);

			&:last-child {
				border: 0;
			}
		}

		.img {
			width: 30px;
			height: 30px;
			max-width: 30px;
			margin-right: 0;
			height: 30px;
			max-height: 30px;
			min-height: 0;
		}

		.bgImage {
			width: 30px;
			height: 30px;
			margin-right: 0;
			border-radius: 2px;
		}

		.time {
			font-size: 11px !important;
		}
	}
}

.standard-gri-g4 {
	&.measureItem {
		background-color: @wf_gray_lighter;
		padding: 17px 18px 17px 2px;
	}

	li {
		background-color: @wf_gray !important;
	}
}

// MailOutbound
wf-item.template64 {
	.tooltip-inner {
		text-align: left;
		min-width: 200px;
		margin-top: -6px;
	}

	.subject {
		font-size: 16px;
		color: rgb(68, 68, 68)
	}

	.indicator {
		padding-top: 10px;
		font-size: 20px;
		color: #888;
		text-align: center;

		.icon {
			display: inline-block;
			width: 50px;
		}

		.fail {
			color: rgb(230, 106, 106);
		}
	}
}

wf-item.template70, wf-item.template71 {
	.toggler {
		width: 30px;
		height: 30px;
		margin-right: 15px;
		font-size: 20px;
		text-align: center;
		line-height: 30px;
		border-radius: 100%;
		cursor: pointer;

		&:hover {
			background-color: rgba(0,0,0,0.1);
		}

		.collapsed {
			margin-left: 3px;
		}
	}

	.item-row {
		padding-left: 45px;
		padding-right: 0;
		min-height: 43px;

		.toggler-container {
			width: 45px;
			position: absolute;
			left: 0;
			top: 6px;
		}

		.item-container {
			.bgImage {
				position: static;
				margin-right: 20px;
				width: 70px;
				height: 70px;
			}
		}

		.tools-container {
			position: static;

			wf-item-tools {
				min-height: 37px;

				.dropdown {
					position: absolute;
					right: 0;
				}
			}
		}




		.children-container {
			padding-top: 10px;
		// 	margin-left: 10px;
		// 	border-left: 2px solid #ccc;

		}
	}

	[wf-init-loader] {

		&.loading {
			min-height: 200px;
			background: url("/assets/img/loaders/spinner_mainBg.gif") center center no-repeat;
		}
	}

	.pull-none {
		// overflow: visible;

		// &::after {
		// 	visibility: hidden;
		// 	display: block;
		// 	font-size: 0;
		// 	content: " ";
		// 	clear: both;
		// 	height: 0;
		// }
	}
}

.layout-public {
	header {
		width: 100%;
		// min-width: 550px;
	}

	#wrapper {
		width: 100% !important;
		max-width: 1300px;
		// min-width: 550px;
		padding-top: 0px;
	}
}

.PublicInfluencesController {
	&, * {
		color: #3B3B3B;
	}

	a, a * {
		color: #2685ee;
	}

	a:hover {
		text-decoration: underline;
	}

	.topHeader {
		padding: 20px 0 30px;
		max-width: 60%;
		margin: auto;
		text-align: center;

		.layout-public & {
			padding-top: 0;
			padding-bottom: 45px;
		}

		.details {
			.fa {
				font-size: 14px;

				&.urlRouteName-icon {
					font-size: 17px;
					position: relative;
					top: 1px;
				}
			}
		}

		.img {
			width: 120px;
			height: 120px;
			position: relative;
			margin: auto;
			margin-bottom: 10px;
		}

		h1 {
			margin-top: 0;
			padding-top: 0;
			margin-left: -1px;
			margin-bottom: 2px;
			font-weight: bold;
			font-size: 31px;

			&, * {
				color: #444;
			}

			i {
				zoom: 0.7;
				position: relative;
				top: -3px;
			}
		}

		p {
			font-size: 15px;
			color: #444;
		}
	}

	.filterBar {
		margin-top: 30px;
		margin-bottom: 30px;
		position: relative;

		&::before {
			// content: "";
			// display: block;
			// position: absolute;
			// top: 50%;
			// right: 0;
			// left: 0;
			// height: 1px;
			// background-color: #ddd;
		}

		.pull-none {
			overflow: visible;
		}

		.answerableItemsFilter {
			margin-top: 30px;
		}

		.input-group {
			box-shadow: 0 0 0 1px #B6B6B6 inset;
			// width: 507px;
			width: auto !important;
			display: flex;

			* {
				background-color: transparent;
				border: 0;
				font-size: 14px;
			}

			input {
				.placeholder(#929292);
			}

			.input-group-addon i {
				color: #949494;
			}

			.input-group-addon {
				&:first-child {
					min-width: 0;
					width: 25px;
					padding-right: 0;
					text-align: right;
				}

				&.search-count {
					padding-right: 12px;
					line-height: 17px;
				}
			}

			.form-control {
				background: transparent;
				outline: none;
				font-weight: normal;
			}

			.ui-select-container {
				width: 100% !important;

				.btn-default-focus {
					outline: none !important;
					box-shadow: none !important;
				}

				.ui-select-toggle > .caret {
					right: 28px !important;
					&::before {
						font-size: 22px;
						color: #949494;
					}
				}

				.ui-select-toggle > a.btn {
					right: 0 !important;
					i {
						color: #666;
					}

					&:hover i {
						color: #333;
					}

				}

				.ui-select-match-text {
					padding-right: 3em;

					span {
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}
				}

				&.ng-not-empty {
					i.caret {
						display: none;
					}
				}

				.btn {
					height: 37px;
					// padding: 0 !important;

					> * {
						// padding: 9px 10px !important;
					}
				}

				.ui-select-placeholder {
					color: #929292;
				}

				.dropdown-menu {
					background: #fff;
					border-radius: 0;
					border: 1px solid #ccc;
					box-shadow: 0 3px 5px rgba(0,0,0,0.1);

					.ui-select-choices-row > span {
						padding: 3px 9px !important;
					}

					.active {
						> span, * {
							background: #3B3B3B;
							color: #fff;
						}
					}
				}
			}
		}

		.buttons {
			.btn-group {
				button.btn {
					border-radius: 0 !important;
					background-color: transparent;
					// background-color: #F8F8F8;
					font-size: 14px;
					font-weight: 300;
					margin: 0 7px 8px 0 !important;
					box-shadow: 0 0 0 1px #B6B6B6 inset;

					&.selected {
						background-color: #3B3B3B;
						color: #efefef;
						box-shadow: 0 0 0 1px #3B3B3B inset;
					}
				}
			}
		}
	}

	.extendedBarBg {
		background-color: #F5F5F5;
		box-shadow:

			-1000px 0 #f5f5f5,
			1000px 0 #f5f5f5,
			-300px 0 #f5f5f5,
			300px 0 #f5f5f5,
			0 200px #f5f5f5,
			-1000px 200px #f5f5f5,
			1000px 200px #f5f5f5,
			-300px 200px #f5f5f5,
			300px 200px #f5f5f5,
			0 400px #f5f5f5,
			-1000px 400px #f5f5f5,
			1000px 400px #f5f5f5,
			-300px 400px #f5f5f5,
			300px 400px #f5f5f5;
		// -1000px 0 #f5f5f5, 1000px 0 #f5f5f5, 1000px 500px #f5f5f5, -1000px 500px #f5f5f5, 0 500px #f5f5f5,
		// 	-100px 0 #f5f5f5, 100px 0 #f5f5f5, 100px 50px #f5f5f5, -100px 50px #f5f5f5, 0 50px #f5f5f5;
		padding: 30px 0;

		@media @tablet {
			box-shadow: -800px 0 #F5F5F5, 800px 0 #F5F5F5;
		}

		@media @mobile {
			box-shadow: -400px 0 #F5F5F5, 400px 0 #F5F5F5;
		}
	}

	.orgCountInfo {
		font-size: 22px;
		font-weight: 300;
		padding-bottom: 30px;
	}

	.map {
		box-shadow: 0 1px 1px 1px rgba(0,0,0,0.07);
		width: 367px;
		height: 364px;
		// float: right;
		display: block;

		.map-infoWindow {
			max-width: 160px;
		}
	}

	.influences {
		.colHeaders {
			border-bottom: 1px solid #646464;
			padding: 15px 0;
			color: #545454;

			.col-xs-2 {
				@media @tablet {
					padding-left: 34px !important;
			}

				@media @mobile {
					padding-left: 20px !important;
			}
		}
		}

		&.loadingAnswerStats {
			.questionAnswer {
				background-repeat: no-repeat;
				background-position: center center;
				background-image: url("../img/loaders/spinner_subListInBox-gray.gif");
				width: 30px;
				height: 30px;

				div {
					display: none;
				}
			}
		}

		li {
			padding: 20px 4px;
			border-top: 1px solid #D0CFCF;

			.questionAnswer {
				height: 30px;
				padding-top: 4px;
				padding-left: 9px;
				font-size: 15px;

				.answerText {
					width: 110px;
					float: left;
				}

				.relatedContentToggler {
					float: left;
				}
			}

			.img {
				width: 50px;
				height: 50px;
				min-height: 1px;
				position: relative;
				margin-right: 17px;
			}

			.org {
				font-size: 16px;

				.name {
					font-weight: 600;
				}

				.details {
					font-weight: 300;
				}
			}

			.categories {
				padding-top: 2px;
				.catItems {
					.catItem {
						overflow: hidden;

						& + .catItem {
							padding-top: 6px;
						}

						.catImg {
							width: 19px;
							height: 19px;
							border-radius: 100%;
							margin-right: 6px;
							float: left;
						}

						.text {
							float: left;
							padding-top: 1px;
						}
					}
				}
			}


			&:first-child {
				border: 0;
			}

			.btn.openInfluence {
				border: 2px solid #333;
				background-color: transparent;
				text-transform: uppercase;
				font-size: 13px;
				border-radius: 4px;
				padding: 6px 16px;
				font-weight: 600;
				color: #555;
				float: right;
				box-shadow: none;

				&:hover {
					background-color: #fff;
				}
			}

			.stats {
				position: relative;
				top: -3px;
				left: -10px;

				.statItem {
					width: 60px;
					padding: 0 5px;
					text-align: center;
					margin-bottom: 6px;

					.chart {
						width: 45px;
						height: 45px;
						margin: auto;
					}
					.title {
						font-size: 9px;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}
				}
			}

			.relatedContent {
				padding: 15px 10px 0 68px;

				.loading {
					// padding: 10px;
					height: 80px;
					background: url(/assets/img/loaders/spinner_subListInBox-gray.gif) no-repeat center 30%;

					// i {
					// 	color: #ddd;
					// 	font-size: 22px;
					// }
				}

				.list-group-item {
					padding: 15px 0;
				}

				.listHeader {
					font-size: 14px;
					font-weight: 500;
				}

				.wf-headerText {
					font-size: 14px;
				}

				.wf-bodyText {
					font-size: 14px;
				}
			}
		}
	}

	//makeing the chart you see when opening an influence repsonsive
	.svg-container {
		>svg {
			width: 100% !important;
			height: 100% !important;
		}
	}

	//Quick Fixes for making modal dialog responsive, overriding bootstrap rules
	.modal-dialog {
		@media @modal-fix-public {
			width: 90%;
		}

		@media @modal-fix-public2 {
			width: 90%;
		}

		@media @modal-fix-public3 {
			width: 90%;
		}
	}

	.public-contact {
		border-radius: 3px;
		background-color: @wf_gray_light;
		padding: 16px;
		margin-right: 16px;
		margin-top: 16px;
		width: 384px;
		height: 132px;

		span {
			display: block;
			width: 100%;
			border-top: 1px solid @wf_gray;
			margin: 26px 0 16px 0;
		}

		p {
			color: @wf_black;
			font-size: 13px;
			display: inline-block;
			margin-bottom: 0px;
			padding-top: 6px;
		}

		.fa {
			display: inline-block;
			margin-right: 6px;
		}

		.btn {
			font-size: 12px;
			font-weight: 600;
			width: 115px;
			margin-bottom: 12px;
			border-radius: 2px;

			&.btn-default {
				background-color: @wf_blue !important;
				color: #fff;
				border: none;

				&:hover {
					background-color: @wf_blue_dark !important;
				}
			}

			&.btn-white {
				background-color: transparent !important;
				color: @wf_black;
				border: none;
				width: 115px;
			}
		}
	}
}

.embed-player {

	.embed-html-wrapper {
		position: relative;

		> * {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
		&.loading .embed-preview {
			&::before, &::after {
				display: none;
			}
		}

		.embed-preview {
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			cursor: pointer;
			// box-shadow: 0 0 0 1000px rgba(0,0,0,0.05) inset;

			&, &::before, &::after {
				transition-duration: .1s;
			}


			&:hover {
				// box-shadow: 0 0 0 1000px rgba(0,0,0,0) inset;

				&::before, &::after {
					transform: scale(1.05);
				}

				&::before {
					background-color: rgba(20, 20, 20, 0.95);
				}
			}

			&::after {
				content: "";
				display: block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 16px 0 16px 28px;
				border-color: transparent transparent transparent #fff;
				position: absolute;
				top: 50%;
				left: 50%;
				margin: -16px 0 0 -11px;
			}

			&::before {
				content: "";
				display: block;
				width: 65px;
				height: 65px;
				border-radius: 50%;
				background-color: rgba(0, 0, 0, 0.7);
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				box-shadow: 0 0 3px 0 rgba(255,255,255,0.1);
			}
		}
	}
}

.UsersController {
	padding-bottom: 100px;

	.panel-body {
		padding: 40px;
	}

	.subListInBox-gray {
		margin-left: 0;
		margin-right: 0;
		padding: 0;
	}

	.user-item {
		wf-item-component {
			.image { margin-right: 20px; }


			.item-tools {
				display: flex;
				align-items: center;
				button { background-color: white !important; }
			}

			.transclude-side {
				display: flex;
				flex-basis: 100% !important;
				flex-shrink: 1 !important;

				transclude-side {
					display: flex !important;
					flex-basis: 100%;
					justify-content: space-between;

					.access {
						display: flex;
						flex-direction: column;
						justify-content: center;

						.customRoot {
							.rootLabel {
								display: block;
								font-size: 12px;
								padding-bottom: 2px;
							}
						}
					}// end of: .access

					.status {
						display: flex;
						align-items: center;
						margin: 0 0 0 20px;

						.user-active {
							display: inline;
							line-height: 35px;
							text-align: right;
							span{
								font-weight: 700;
							}

							i{
								margin-left: 5px;
								font-size: 11px;

								&.activated{
									color: @wf_green;
								}

								&.not-activated{
									color: @wf_red;
								}
							}
						}

						.viewMode {
							display: block;
							line-height: 20px;
							background-color: @wf_gray_lighter;
							clear: both;
							padding: 5px;
							border-radius: 3px;

							span{
								font-weight: 400 !important;
							}

							i {
								font-size: 17px;
								position: relative;
								top: 2px;
								color: #707980;
							}
						}

						button.send-email {
							margin-right: 12px;
							padding-left: 12px;
							padding-right: 12px;
							background-color: @wf_orange;
							border-color: @wf_orange_dark;
							i { margin-right: 5px; }
							.box-shadow-pulse-effect(@wf_orange);
						}
					}// end of: .status
				}
			}// end of: .transclude

			.inner-content {
				flex: initial !important;
				flex-basis: 70% !important;

				.headers {
					margin-bottom: 0 !important;
				}
			}
		}// end of: wf-item-component
	}// end of: .user-item
}// end of: .UsersController

.ManageController {
	.divider-helper {
		position: relative;

		&:after {
			content: "";
			display: none;
			background: #ccc;
			width: 1px;
			position: absolute;
			top: 10px;
			bottom: 5px;
			left: 58.333%;
			margin-left: 1px;
		}

		.internalVC {
			padding: 18px 26px;

			.remainingUser {
				// border-bottom: 1px solid @wf_gray;

				&:hover {
					.remainingText {
						opacity: 0.8;
					}

					.remainingItems {
						background-color: @wf_orange_dark;
						padding: 1px 7px;
					}
				}
			}

			wf-item {
				width: 60%;
				margin-bottom: 15px;
			}

			.img {
				min-width: 25px;
				width: 25px;
				min-height: 25px;
				height: 25px;
				border-radius: 25px;
			}

			.wf-bodyText {
				display: none;
			}

			.remainingItems {
				padding: 1px 5px;
				border-radius: 3px;
				background-color: @wf_orange;
				color: #fff;
				font-weight: 500;
				margin: 0 4px 0 8px;
				font-size: 14px;
				transition: all 200ms ease-in-out;
			}

			.remainingText {
				padding-top: 2px;
				opacity: 0.5;
				transition: all 200ms ease-in-out;
			}
		}

		.informationRepository {
			background-color: #fff;
    		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 -1px 0px 0 rgba(0, 0, 0, 0.03);
			padding: 1px 5px 1px 12px;

			.panel-icon {
				font-size: 20px;
				margin: 30px;
			}

			.panel {
    			box-shadow: none;

				.wf-headerText {
					margin: 0px;
				}
			}

			.wf-headerText {
				margin: 20px;
			}

			.tabs {
				margin-left: 20px;

				.btn {
					padding: 5px 7px;
				}
			}

			.openStructure {
				margin-top: 31px;
    			margin-right: -18px;
			}
		}
	}

	h3 {
		margin-top: 0;
		font-size: 22px;
		font-weight: 500;
		margin-bottom: 16px;
	}

	.userData {
		.tabs {
			padding-top: 9px;
			// padding-bottom: 20px;
			// margin: -10px;
			margin-bottom: 12px;
		}
	}

	.sideItem {
		* {
			cursor: pointer;
		}

		&:hover {
			background-color: rgba(0,0,0,0.04);
			box-shadow: 0 0 0 5px rgba(0,0,0,0.04);
		}

		.wf-headerText {
			color: #2e94de;
			font-weight: bold;
		}
	}

	.PageController {
		.page-heading {
			display: none;
		}

		.col-sm-8 {
			width: 100% !important;
		}
	}

	.panel-icon {
		font-size: 20px;
		margin: 5px;
		color: #d6d6d6;
	}

	.sideBar {
		.sideBarItem {
			margin-bottom: 20px;
			padding: 20px 0 10px;

			.categorizedEntries {
				margin-top: 10px;
				margin-left: 60px;

				> div {
					margin-right: 5px;
					margin-bottom: 1px;

					a {
						display: inline-block;
						position: relative;
						font-weight: 400;
						max-width: 300px;
						padding: 5px 9px 4px 6px;
						line-height: 15px;
						background-color: #ecf0f1;
						border-radius: 3px;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
						color: #555;

						i.categoryType {
							width: 17px;
							color: #576673;
							font-size: 13px;

							&.report {
								font-size: 12px;
								margin-top: -2px;
							}
						}

						i.arrow {
							position: absolute;
							right: 5px;
							top: 5px;
							font-size: 13px;
							opacity: 0.5;
							display: none;
						}

						&:hover {
							background-color: #d9dedf;
						}
					}
				}
			}

			wf-item {
				cursor: pointer;

				&:hover {
					.wf-headerText {
						font-weight: 500;
						letter-spacing: -0.08px;
					}

					.wf-bodyText {
						color: @wf_black !important;
					}
				}

				.description-text {
					display: none;
				}

				.wf-bodyText {
					height: 15px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					width: 92%;
					color: @wf_gray_dark !important;
				}

				.img {
					transition: all 200ms ease;
				}
			}
		}

		.emptyList-message {
			text-align: center;
			padding: 19px 0;
			width: 100%;
			border-radius: 3px;
			background-color: rgba(52, 152, 219, 0.1);
			margin: 10px 0px 18px;
			border: 1px solid rgba(52, 152, 219, 0.2);
			color: rgba(52, 152, 219,1.0);

			&:hover {
				background-color: rgba(52, 152, 219, 0.15);
				border: 1px solid rgba(52, 152, 219, 0.3);
			}

			.body {
				margin: 0px;
				max-width: none;
			}
		}

		.guidance-btn {
				display: none;
		}

		.description-text{
			padding-bottom: 4px;
		}

		// .panel-body {
		// 	box-sizing: border-box;
		// 	border: 1px solid transparent;
		// 	.transition(border-color .35s ease);
		// 	padding: 0px;

		// 	&:hover {
		// 		border: 1px solid @wf_gray;
		// 		.transition(border-color .1s ease);
		// 	}

		// 	.text {
		// 		height: 55px;
		// 		padding-top: 10px;
		// 	}

		// 	.wf-bodyText {
		// 		// height: 15px;
		// 		overflow: hidden;
		// 		text-overflow: ellipsis;
		// 		white-space: nowrap;
		// 		width: 92%;
		// 		color: @wf_gray_dark !important;
		// 	}

		// 	.img {
		// 		max-width: 70px;
		// 		min-width: 70px;
		// 		min-height: 70px;
		// 		margin: -1px 15px -1px -1px;
		// 	}
		// }

		.wf-bodyText {

		}
	}

	.openStructure {
		margin: 7px;
		color: @wf_gray;

		span {
			margin: 0px !important;
		}

		&:hover {
			color: @wf_blue;
		}
	}
}

.PageController {
	&.objType13 {
		// Influence
		.sendToDropDown {
			display: none;
		}
	}
}


wf-object-settings {
	display: block;

	li + li {
		padding-top: 5px;
	}

	.button-wrapper {
		padding-top: 5px;
	}

	&.compact-layout {
		.list-wrapper {
			float: left;
			padding-right: 17px;
		}

		.button-wrapper {
			float: left;
			padding-top: 0;
			padding-bottom: 10px;
		}
	}
}

.StandardsAndGuidelinesController {
	.searchResult {
		width: 50%;

		.searchResultItem {
			padding: 14px 0 15px;

			.title {
				margin: 0;
				padding-bottom: 4px;
			}

			.description {
				white-space: pre-wrap;
			}

			.belonging {
				font-weight: bold;
				color: #777;
				padding-top: 8px;
			}
		}
	}
}

.iso-texts {
	h1 {
		margin: 8px 0 8px 0;
		padding: 0 0 8px 0;
	}

	h2 {
		margin: 20px 0 8px 0;
		padding: 0 0 8px 0;
	}

	h3{
		margin: 15px 0 6px 0;
		padding: 0 0 6px 0;
	}

	h4{
		margin: 10px 0 4px 0;
		padding: 0 0 4px 0;
	}

	h5{
		margin: 5px 0 4px 0;
		padding: 0 0 4px 0;
	}
}


wf-influence-signing {
	display: block;

	.verificationDoc {
		a {
			display: inline-block;
			padding: 3px 0;
			margin-left: 1px;

			i {
				font-size: 14px;
				padding-right: 7px;
				color: #333;
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

wf-access-tag {
	display: block;
	@deniedColor: @wf_red;
	@allowedColor: @wf_green_dark;

	&.saving {
		pointer-events: none;

		.selector-large {
			.circular-indicator {
				box-shadow: none !important; // Shadow renders badly when combined with loader gif so borders is used instead
				border: 1px fade(@wf_gray, 50%) solid;
				background: #fff;
				.spinner(22px);

				* {
					visibility: hidden;
				}
			}
		}
	}

	.permissions-crud {
		&.grid {
			width: 450px;

			wf-access-tag-selector {
				float: left;
			}

			.selector-small {
				margin-right: 10px;
				margin-bottom: 3px;

				.text {
					width: 120px;
				}

				.permission-name {
					width: 50px;
					font-size: 10px;
					text-transform: uppercase;
				}
			}
		}
	}

	.permission-selector {
		position: relative;

		&.open .dropdown-toggle, .dropdown-toggle:hover {
			box-shadow: 0 0 0 1px rgba(0,0,0,0.09) inset;
			background-color: #fff;

			.circular-indicator {
				background-color: #fff;
			}

			.text {
				color: #888;

				span {
					color: #777;
				}
			}
		}

		.dropdown-toggle {
			cursor: pointer;
			padding-right: 12px;
			border-radius: 29px 4px 4px 29px;
		}

		.dropdown-menu {
			i {
				width: 20px;
				text-align: left;

				&.fa-lock {
					color: @deniedColor;
				}

				&.fa-unlock {
					color: @allowedColor;
				}
			}
		}

		.circular-indicator {
			border-radius: 100%;
			text-align: center;
			cursor: pointer;

			* {
				display: none;
			}

			&.allowed {
				color: @allowedColor;
				box-shadow: 0 0 0 1px fade(@allowedColor, 40%) inset;

				.icon-allowed {
					display: block;
				}
			}

			&.denied {
				color: @deniedColor;
				box-shadow: 0 0 0 1px fade(@deniedColor, 40%) inset;

				.icon-denied {
					display: block;
				}
			}
		}

		.text {
			color: @wf_gray_dark;

			> div {
				span {
					font-weight: 500;
					display: inline-block;
					color: darken(@wf_gray_dark, 10%)
				}
			}

			.permission-user {
			}
		}

		&.noOrgPermission {
			.text {
				.permission-organization {
					opacity: 0.5;
				}
			}
		}

		&.noUserPermission {
			.text {
				.permission-user {
					opacity: 0.5;
				}
			}
		}
	}

	.selector-large {
		.circular-indicator {
			width: 50px;
			height: 50px;
			margin-right: 12px;

			* {
				font-size: 22px;
				line-height: 49px;
			}
		}

		.text {
			padding-top: 9px;

			> div {
				span {
					font-weight: 500;
					padding-left: 2px;
				}
			}

			.permission-user {
				padding-top: 8px;
				padding-right: 10px;
			}
		}
	}

	.selector-small {
		line-height: 18px;

		.permission-name {
			width: 50px;
		}

		.dropdown-toggle {
			border-radius: 4px;
			padding-left: 5px;
			padding-right: 6px;
		}

		.circular-indicator {
			width: 18px;
			height: 18px;
			margin-right: 5px;

			* {
				font-size: 9px;
				line-height: 17px;
			}
		}

		.text {
			> div {
				span {
					font-weight: 500;
					padding-left: 2px;
				}
			}

			.permission-user {
			}
		}
	}
}

wf-access-tag-selector {
	display: block;
}

.ReportController {
	.report-page-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 45px 0 35px 0px;

		.page-title {
			flex-grow: 10;
			h1 {
				color: @wf_black;
				margin: 0;
				font-size: 38px;
				span { font-weight: 600; }
			}
		}

		.invite-colleagues {
			flex-shrink: 0;
			.invite-wrapper {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;

				background-color: @wf_green_dark;
				padding: 16px;
				border-radius: 3px;

				&:hover {
					background-color: @wf_green_dark;

					.circle-icon-wrapper {
						i { color: @wf_green_dark; }
					}
				}

				.circle-icon-wrapper {
					display: flex;
					align-items: center;
					justify-content: center;

					width: 40px;
					height: 40px;
					border-radius: 50%;
					background-color: white;
					margin-right: 16px;

					i {
						color: @wf_green_dark;
						font-size: 18px;
					}
				}

				.invite-text {
					display: flex;
					flex-direction: column;
					align-content: center;

					span {
						text-align: left;
						color: white;

						&.main-text {
							font-size: 16px;
							font-weight: 600;
						}

						&.description-text {
							font-size: 14px;
							font-weight: 400;
						}
					}
				}

				button.invite-colleagues-button {
					background-color: white;
					text-transform: uppercase;
					font-size: 14px;
					color: @wf_green_dark;
					margin-left: 16px;
					font-weight: 500;
					padding: 6px 20px;

					&:hover {
						color: @wf_green;
					}
				}
			}
		}
	}

	.report-page-info {
		margin-bottom: 15px;
		i { margin-right: 10px; }
		i, span.description-text {
			font-size: 20px;
			color: @wf_black;
		}
	}

	panel {
		.panel {
			margin-bottom: 30px;

			.panel-body { padding: 0px; }

			.panel-header {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				border-bottom: 1px solid @wf_gray_light;
				margin: 40px 40px 25px 40px;
				padding-bottom: 25px;

				.panel-header-top {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 20px;
				}

				.panel-header-only {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;

					.header-title {
						font-size: 22px;
						font-weight: 600;
						color: @wf_black_dark;
					}

					.header-link {
						font-size: 14px;
						color: @wf_blue;
						cursor: pointer;
						i { margin-right: 10px; }

						&:hover {
							text-decoration: underline;
						}
					}
				}

				.header-label {
					text-transform: uppercase;
					color: @wf_gray_dark;
					font-weight: 500;

					&:hover { cursor: pointer; }

					span, i { font-size: 12px; }

					&:before {
						font-weight: 400;
						text-transform: none;
						text-align: left;
						width: 320px;
						min-width: 320px
					}
				}

				.panel-header-bottom {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;

					.panel-title {
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;
						flex-grow: 5;
						flex-shrink: 20;

						.org-image {
							margin-right: 15px;
							img {
								border-radius: 3px;
							}
						}

						.org-info {
							display: flex;
							flex-direction: column;
							justify-content: flex-start;

							.org-name {
								font-weight: 500;
								font-size: 16px;
								color: @wf_black_dark;
							}
							.org-reg-number {
								font-size: 12px;
								color: @wf_gray_dark;
							}
						}
					}

					.contact-persons {
						display: flex;
						flex-direction: row-reverse;
						justify-content: flex-end;
						flex-wrap: wrap;
						flex-shrink: 0;

						.cp-header {
							padding: 5px 0;
							line-height: 15px;
						}

						.cp-header {
							padding-right: 10px;
						}

						.as-link {
							float: left;
						}

						i { padding-right: 5px; }

						.contact-person-img {
							padding: 5px 10px;
							width: 35px;
							height: 35px;
							border-radius: 50%;
							background-repeat: no-repeat;
							background-size: cover;

							.text {
								white-space: nowrap;

								.wf-headerText {
									font-weight: 600;
									color: #666;
									clear: both;
									float: none;
								}

								.email { font-size: 11px; }
							}

							.wf-image {
								border-radius: 100%;
								margin-right: 10px;
							}
						}


						.contact-persons-as-circles {
							display: flex;
							flex-direction: row-reverse;
							justify-content: flex-end;
							flex-wrap: wrap;
							flex-shrink: 10;

							.contact-person-img {
								box-shadow: 0 0 0 1px white;
								margin-left: -10px !important;

								&:hover {
									transform: scale(1.1);
									transition: transform .1s;
								}
							}

							.contact-person-tooltip {
								text-align: left;
								.contact-person-name {
									font-size: 14px;
									font-weight: 500;
								}

								.contact-person-email {
									display: block;
									font-weight: 300;
								}
							}
						}

						.contact-persons-with-info {
							display: flex;
							flex-direction: row;
							justify-content: flex-end;
							flex-wrap: wrap;
							flex-shrink: 10;

							.contact-person-wrapper {
								display: flex;
								flex-direction: row;
								justify-content: flex-start;
								align-items: center;

								&:not(:last-child) {
									margin-right: 25px;
								}

								.contact-person-img {
									margin-right: 15px;
								}

								.contact-person-info {
									display: flex;
									flex-direction: column;
									justify-content: center;

									.contact-person-name {
										font-weight: 600;
										color: #666666;
										max-width: 200px;
										text-overflow: ellipsis;
										overflow: hidden;
									}

									.contact-person-email {
										font-size: 11px;
										font-weight: 400;
										color: #AAAAAA;
										max-width: 200px;
										text-overflow: ellipsis;
										overflow: hidden;
									}
								}
							}
						}
					}

					.add-contact-person {
						margin-left: 15px;
						padding: 5px 10px;
						width: 35px;
						height: 35px;
						border-radius: 50%;
						border: 1px solid @wf_gray_dark;
						display: flex;
						align-items: center;
						justify-content: center;
						color: @wf_gray_dark;
						cursor: pointer;

						&:hover {
							color: @wf_blue;
							border-color: @wf_blue;
						}
					}
				}
			}

			.panel-content {
				padding-left: 25px;
				padding-right: 25px;
				padding-bottom: 40px;

				.influence {
					border-top: 0;
				}

				wf-item-component {
					.wf-item-component {
						.container {
							.image {
								margin-right: 20px;
							}
						}
						.content-wrapper {
							display: flex;
							flex-direction: row;


							.content {
								align-items: center;
								flex-basis: 100%;

								.headers {
									.wf-headerText {
										font-size: 18px;
									}
								}

							}
						}

						.transclude-side {
							display: flex;
							flex-direction: row;
							align-items: center;

							transclude-side {
								display: flex;
								flex-direction: row;
								align-items: center;
							}
						}
					}
				}

				.networkHeader {
					font-weight: 600;
					font-size: 18px;
				}

				.subItem {
					padding: 5px 0;

					&:hover .wf-headerText {
						text-decoration: underline;
					}
				}

				.panel-body {
					padding: 30px;
				}

				.influence-fulfillment-status {
					width: 260px;
					padding-top: 2px;
					padding-left: 30px;
					padding-right: 50px;

					.sender {
						padding-bottom: 11px;
					}

					.slash {
						display: inline-block;
						padding: 0 1px;
					}

					.progress {
						height: 6px;
					}
				}
			}
		}
	}

	wf-promotions {
		.promotional-title {
			margin-top: 30px;
			margin-bottom: 30px;

			&.arrow {
				.text {
					h2 {
						span {
							color: @wf_gray;
							font-size: 16px;
						}
					}
				}
			}

			.text {
				h2 {
					width:100%;
					font-size: 22px;
					font-weight: 500;
					text-align: center;
					border-bottom: 1px dashed #d5d5d5;
					line-height: 0.1em;
					margin: 10px 0;

					span {
						background:@wf_gray_lighter;
						padding: 0 20px;
						line-height: 0.1em;

						&:before {
							background:@wf_gray_lighter;
							padding: 0 20px;
						}
					}
				}
			}
		}//end of: .promotional-title
	}

	.emptyList-message {
		margin-bottom: @line-height-computed;
		background-color: @panel-bg;
		border: 1px solid transparent;
		border-radius: @panel-border-radius;
		.box-shadow(0 1px 1px rgba(0,0,0,.05));
	}
}

.influenceViewer {
	.influenceViewer-header {
		.image {
			width: 50px;
			height: 50px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}
}

.ui-select-container {
	// width: 100% !important;

	// .btn-default-focus {
	// 	outline: none !important;
	// 	box-shadow: none !important;
	// }

	// .ui-select-toggle > .caret {
	// 	right: 28px !important;
	// 	&::before {
	// 		font-size: 22px;
	// 		color: #949494;
	// 	}
	// }

	// .ui-select-toggle > a.btn {
	// 	right: 0 !important;
	// 	i {
	// 		color: #666;
	// 	}

	// 	&:hover i {
	// 		color: #333;
	// 	}

	// }

	// .ui-select-match-text {
	// 	padding-right: 3em;

	// 	span {
	// 		text-overflow: ellipsis;
	// 		overflow: hidden;
	// 		white-space: nowrap;
	// 	}
	// }

	// &.ng-not-empty {
	// 	i.caret {
	// 		display: none;
	// 	}
	// }

	// .btn {
	// 	height: 37px;
	// 	// padding: 0 !important;

	// 	> * {
	// 		// padding: 9px 10px !important;
	// 	}
	// }

	// .ui-select-placeholder {
	// 	color: #929292;
	// }

	// .dropdown-menu {
	// 	background: #fff;
	// 	border-radius: 0;
	// 	border: 1px solid #ccc;
	// 	box-shadow: 0 3px 5px rgba(0,0,0,0.1);

	// 	.ui-select-choices-row > span {
	// 		padding: 3px 9px !important;
	// 	}

	// 	.active {
	// 		> span, * {
	// 			background: #3B3B3B;
	// 			color: #fff;
	// 		}
	// 	}
	// }
}

wf-dashboard-card {
	display: block;

	&:hover {
		> .card-item {
			.card-inner {
				transition-duration: .1s;
				box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.15) inset, 0 0 100px 0px rgba(0, 0, 0, 0.05) inset;
				background-color: rgba(0, 0, 0, 0.04);
			}
		}
	}

	> .card-item {
		display: block;
		border-radius: 2px;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 -1px 0px 0 rgba(0, 0, 0, 0.03);
		margin-bottom: 15px;
		color: white;
		background-color: #888;
		text-shadow: 0 0px 3px rgba(0, 0, 0, 0.15);
		height: 350px;
		position: relative;

		.card-inner {
			transition-duration: .2s;
			border-radius: 2px;
			padding: 23px;
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) inset, 0 0 50px 0 rgba(0, 0, 0, 0) inset;
			background-color: rgba(0, 0, 0, 0);
			height: 100%;
		}


		.openStructure {
			color: rgba(255, 255, 255, 0.7);
			position: absolute;
			top: 30px;
			right: 40px;
			display: block;

			&:hover {
				text-decoration: underline;
				color: rgba(255, 255, 255, 1);
			}
		}

		.card-header {
			font-size: 21px;
			text-transform: uppercase;
			font-weight: 500;
			margin: auto;
			text-align: center;
			padding-bottom: 25px;
		}

		ul.totals {
			text-align: center;

			.line-legend {
				height: 21px;
				width: 2px;
				display: inline-block;
				background-color: #fff;
				margin-right: 4px;
				margin-left: -15px;

				&.dashed {
					position: relative;
					top: -8px;
					height: 5px;

					&:before, &:after {
						content: "";
						display: block;
						position: absolute;
						background-color: #fff;
						width: 2px;
						height: 5px;
						left: 0;
					}

					&:before {
						top: -8px;
					}

					&:after {
						top: 8px;
					}
				}
			}

			&.emphasize {
				li {
					/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+90&0.65+0,0+90 */
					background: -moz-linear-gradient(top, rgba(0,0,0,0.04) 0%, rgba(0,0,0,0) 90%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top, rgba(0,0,0,0.04) 0%,rgba(0,0,0,0) 90%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom, rgba(0,0,0,0.04) 0%,rgba(0,0,0,0) 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					border-radius: 4px;
					box-shadow: 0 -1px rgba(255, 255, 255, 0.11);
				}
			}

			&.grid {
				li {
					display: inline-block;
					width: 39%;
					border-left: 0 !important;
					margin: 7px 7px 10px !important;
					padding: 20px 5px;
				}
			}

			li {
				display: inline-block;
				padding: 0 25px;

				&:not(:first-child) {
					border-left: 1px solid rgba(255,255,255,0.2);
					margin-left: -1px;
				}

				.number-value {
					font-size: 29px;
					font-weight: 600;
					text-align: center;
				}

				.number-label {
					color: rgba(255,255,255,0.7);
					text-shadow: none;
					max-width: 170px;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}

		.card-stats {
			position: relative;

			&.loading {
				.spinner(30px, "light");
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
			}

			&.donut {
				padding-top: 15px;

				.totals {
					position: absolute;
					left: 0;
					right: 0;
					top: 87px;
				}
			}

			.card-graph {
				opacity: 0;

				.emptyState {
					height: 140px;
					position: relative;

					&:before {
						content: "";
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						background: url("/assets/img/misc/linechart_white.png") center center no-repeat;
						background-size: contain;
						opacity: 0.1;
					}

					span {
						display: inline-block;
						background: rgba(0, 0, 0, 0.12);
						margin: auto;
						width: auto;
						margin-top: 52px;
						padding: 10px 15px;
						font-weight: 600;
						color: rgba(255,255,255,0.9);
						font-size: 12px;
						border-radius: 4px;
					}
				}

				.lineChart {
					padding: 0 10px 0;
					position: relative;
				}

				.donutChart {
					margin: auto;
					width: 200px;
					height: 200px;
				}
			}
		}
	}

	&.valueChain {
		> .card-item .card-graph .emptyState:before {
			background: url("/assets/img/misc/linechart_double_white.png") center center no-repeat;
			opacity: 0.2;
		}
	}

	&.report {
		> .card-item .card-graph .emptyState {
			height: 160px;

			&:before {
				background: url("/assets/img/icons/mailbox1_neutral.png") center center no-repeat;
				opacity: 0.2;
			}

			span {
				background: transparent;
				position: absolute;
				width: 100%;
				left: 0;
				bottom: -45px;
			}
		}
	}
}

wf-measure-display-variant {
	display: block;
	text-align: center;
	position: relative;
	padding-bottom: 65px;
	min-height: 205px;

	.text {
		font-size: 12px;
	}

	.icon {
		font-size: 38px;
		height: 45px;

		.image {
			display: inline-block;
			width: 37px;
			height: 37px;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
		}
	}

	.value {
		padding-top: 6px;
		font-size: 32px;
		font-weight: 600;
		min-height: 60px;
		white-space: nowrap;
	}

	.chart {
		padding: 5px 5px 0;
		position: absolute;
		width: 100%;
		bottom: 0;
	}
}

.measure-display-variant-wrapper {
	& + & {
		box-shadow: 1px 0 rgba(0,0,0,0.1) inset;
	}
}

wf-data-answering {
	> div:not(:first-child) {
		padding-top: 4px;
	}
}

.itemWfid {
	wf-item.template3 &, wf-item-component & {
		position: absolute;
		top: 34px;
		left: 0;
		right: 0;
		font-size: 9px;
		color: #afafaf;
		text-align: center;
		white-space: nowrap;
		cursor: text;
	}

	wf-item-component & {
		top: 40px;
	}
}

[tooltip-title] {
	position: relative;
	z-index: 2;
	cursor: pointer;

	&:before,
	&:after {
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
	}

	&[tooltip-position-top-left] {
		&:before {
			left: -5px;
			right: initial;
		}
	}


	&:before {
		content: attr(tooltip-title);
		position: absolute;
		text-align: center;
		font-size: 11px;
		color: #fff;
		right: 0px;
		bottom: 150%;
		margin-bottom: -2px;
		padding: 5px 8px;
		border-radius: 2px;
		border-radius: 3px;
		background-color: rgba(24, 42, 60, 0.98);
		white-space: nowrap;
	}

	/* Triangle hack to make tooltip look like a speech bubble */
	&:after {
		position: absolute;
		bottom: 121%;
		left: 50%;
		margin-left: -5px;
		width: 0;
		border-top: 5px solid rgba(24, 42, 60, 0.98);
		border-right: 5px solid transparent;
		border-left: 5px solid transparent;
		content: " ";
		font-size: 0;
		line-height: 0;
	}

	&:hover:before,
	&:hover:after {
		visibility: visible;
		opacity: 1;
		animation: fadein .2s ease-in;
	}
}//end of: [tooltip-title] - Tooltip

@media screen and (min-width: 0) and (max-width: @screen-xs-min) {
	.UsersController {
		wf-item-component {
			.content {
				flex-direction: column !important;

				.transclude-side {
					flex-basis: initial !important;
					width: 100% !important;
					margin-left: 0 !important;

					padding: 20px;
					border: 1px solid @wf_gray_lighter;
					border-radius: 3px;

					.access {
						float: none;
					}

					.status {
						text-align: left;
						float: none;
						border-top: 1px solid @wf_gray;
						margin-top: 10px;

						button.send-email {
							float: none;
							display: block;
							margin: 0px !important;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: @screen-xs-min) and (max-width: @screen-sm-max) {
	.UsersController {
		wf-item-component {
			.content {
				flex-direction: column !important;

				.transclude-side {
					flex-basis: initial !important;
					width: 100% !important;
					margin-left: 0 !important;

					padding: 20px;
					border: 1px solid @wf_gray_lighter;
					border-radius: 3px;

					.status {
						text-align: right;
						float: none;
						button.send-email {
							float: none;
							display: block;
							margin: 10px 0px 0px auto !important;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: @screen-sm-max) and (max-width: @screen-md-max) {
	.UsersController {
		wf-item-component {
			.content {
				.transclude-side {
					.status {
						text-align: right;
						float: none;
						button.send-email {
							float: none;
							display: block;
							margin: 10px 0px 0px auto !important;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: @screen-md-min) and (max-width: @screen-md-max) {
	header {
		padding: 0 20px !important;
	}
}
